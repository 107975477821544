import React, { useEffect, useState } from 'react';
import Avatar from '@components/avatar/Avatar';
import ChatBox from '@components/chat/ChatBox';
import LoadingTypeGif from '@images/chat-typing-indicator.gif';

const stepDuration = 2500;
const loadingDuration = 2500;

const Chat1 = ({ form, showAvatar }) => {
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => {
    setIsLoading(false);
  }, loadingDuration);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <ChatBox
          showAvatar={showAvatar}
          content={(
            <>
              Salut
              {' '}
              {form.first_name}
              {' '}
              👋
              <p className="mt-2">
                Je suis
                {' '}
                <strong>Ambre</strong>
                {' '}
                Customer Happiness Manager @Steylemans Assurances.
              </p>
              <p className="mt-2">
                L'offre a été soigneusement sélectionnée!

              </p>
              <p className="mt-2">
                Nous sommes phygital :) Ça veut dire quoi?
              </p>
              <p className="mt-2">
                C'est simple, je suis disponible par email,
                téléphone ou chat quand tu en as besoin.👇
              </p>
            </>
          )}
        />
      )}
    </>
  );
};

const Chat2 = ({ form, showAvatar }) => {
  const [isLoading, setIsLoading] = useState(true);
  setTimeout(() => {
    setIsLoading(false);
  }, loadingDuration);

  return (
    <>
      {isLoading && <Loading />}
      {!isLoading && (
        <ChatBox
          showAvatar={showAvatar}
          content={(
            <>
              Oh, j'oubliais presque... tu peux me joindre au
              {' '}
              <br />
              au +32 2 479 00 99 📞
              {' '}
              ou par email à
              {' '}
              <a href="mailto:welcome@steylemans.pro">welcome@steylemans.pro</a>
              {' '}
              ✉️
              .
            </>
)}
        />
      )}
    </>
  );
};

const Loading = () => (
  <div className="block flex flex-col-reverse md:flex-row items-center justify-items-end md:items-end mt-6 w-full">
    <div className="mt-6">
      <Avatar size="xs" />
    </div>
    <div className="bg-gray-50 rounded-lg p-6 text-sm text-dark ml-6 text-left flex-grow">
      <img src={LoadingTypeGif} style={{ width: '50px' }} />
    </div>
  </div>
);

const AnimatedServices = ({ form }) => {
  const [currentStep, setCurrentStep] = useState(1);

  setTimeout(() => {
    setCurrentStep(2);
  }, stepDuration);

  return (
    <>
      { currentStep >= 1 && <Chat1 form={form} showAvatar={currentStep === 1} />}
      { currentStep === 2 && <Chat2 form={form} showAvatar={currentStep === 2} />}
    </>
  );
};

export default AnimatedServices;
