import React, { useRef } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import { useForm, useFormState } from 'react-hook-form';
import ChatBox from '@components/chat/ChatBox';

function Step({ form, nextStepPath, updateForm }) {
  const buttonRef = useRef(null);
  const {
    register, handleSubmit, formState: { errors }, control,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const submitStep = (data) => {
    updateForm(data, nextStepPath);
  };

  return (
    <form className="text-center flex justify-center flex-col items-center px-6" onSubmit={() => buttonRef.current.click()}>
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">
          Quel est la puissance du moteur (CV)?
        </p>
      </div>
      <div className="flex mt-12 flex-col space-y-4">
        <Form.Input
          type="number"
          name="horse_power"
          min={1}
          register={register}
          rules={{ required: true }}
          defaultValue={form.horse_power}
          autoFocus
          label="Puissance en CV"
          placeholder="Puissance en CV"
          error={errors.horse_power}
        />
      </div>
      <div className="mt-16">
        <Button.Primary type="button" delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} forwardRef={buttonRef} />
      </div>
    </form>
  );
}

export default Step;
