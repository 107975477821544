import React, { useState } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ form, updateForm, nextStepPath }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const submitStep = (data) => {
    updateForm(data, nextStepPath);
  };

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="">
        <p className="font-serif text-3xl text-gray-800">
          Quelle est la date de création de l’entreprise ?
        </p>
      </div>

      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Input
          type="date"
          name="creation_date"
          register={register}
          rules={{ required: true }}
          defaultValue={form.creation_date}
          autoFocus
          label="Date de création"
          placeholder="Date de création"
          error={errors.creation_date}
        />
      </div>

      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
      </div>
    </div>
  );
}

export default Step;
