import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      icon={<i className="far fa-user-injured" />}
      title="Dommages corporels"
      description={(
        <>
          Vous êtes couvert à hauteur de 2.500.000 EUR (confondu avec "dommages matériels).
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-window-frame" />}
      title="Dommages matériels"
      description={(
        <>
          Vous êtes couvert à hauteur de 2.500.000 EUR (confondu avec "dommages corporels) (Franchise 175 €).
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-balance-scale" />}
      title="Protection juridique"
      description={(
        <>
          Vous êtes couvert à hauteur de 25.000€. Défense pénale, recours civil extracontractuel et insolvabilité de tiers.
        </>
  )}
    />
    <PolicyItem
      icon={<i className="far fa-building" />}
      title="Membres de la copropriété"
      description={(
        <>
          La responsabilité des membres de l'association de copropriété qui veillent à la bonne gestion du syndic est toujours prévue dans notre contrat.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-address-card" />}
      title="Responsabilité civile extracontractuelle"
      description={(
        <>
          Pour les dommages causés aux tiers par le personnel préposé.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-medal" />}
      title="Service Steylemans"
      description={(
        <>
          Diagnostic de vos risques, et proposition des garanties adéquates.
          Accompagnement et suivi des sinistres en vue d'obtenir vos indemnités.
        </>
      )}
    />

  </>
);
