import React, { useState } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ form, updateForm, nextStepPath }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'optionValue',
    defaultValue: form.team,
  });

  const submitStep = () => {
    updateForm({ team: optionValue }, nextStepPath);
  };

  const selectedOptions = [
    { k: '1', label: '1', selected: (optionValue === '1') },
    { k: '2', label: '2', selected: (optionValue === '2') },
    { k: '3', label: '3', selected: (optionValue === '3') },
    { k: '3_plus', label: '3+', selected: (optionValue === '3_plus') },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="">
        <p className="font-serif text-3xl text-gray-800">
          Combien de personnes occupe ta Startup ?
        </p>
        <p className="font-serif text-2xl mt-6">Chaque associé actif, ou membre du personnel, compte pour 1 personne.</p>
      </div>

      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options
          options={selectedOptions}
          onChange={(value) => setValue('optionValue', value)}
        />
      </div>

      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.team}
          {...register('optionValue', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
