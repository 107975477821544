import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-pro/css/all.min.css';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from '@components/ScrollToTop';
import Configurator from './containers/Configurator';

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Configurator />
      </Router>
    </div>
  );
}

export default App;
