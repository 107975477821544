import React from 'react';
import PolicyItem from '@components/PolicyItem';
import Banner from '@components/Banner';

export default ({ form }) => (
  <>
    <PolicyItem
      icon={<i className="far fa-user-injured" />}
      title="1 police globale pour toute la famille"
      description={(
        <>
          Vous et tous les membres de votre famille vivant sous le même toit êtes assurés dans presque tous les domaines juridiques
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-building" />}
      title="Avantage fiscal"
      description={(
        <>
          Vous pouvez déduire chaque année 40% de la prime de votre déclaration d'impôts, avec un maximum de 124 euros.
        </>
  )}
    />
    <PolicyItem
      icon={<i className="far fa-globe-europe" />}
      title="Remboursement des frais de justice"
      description={(
        <>
          S'il s'agit d'un procès, nous prendrons en charge vos frais de procédure et les frais d'avocat (que vous choisirez vous-même) dans des plafonds très généreux.
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-medal" />}
      title="Le libre choix de l’avocat"
      description={(
        <>
          Nos spécialistes vous proposent un avocat. Mais vous avez toujours le dernier mot et êtes libre de choisir l’avocat que vous désirez.
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-medal" />}
      title="Service Steylemans"
      description={(
        <>
          Diagnostic de vos risques, et proposition des garanties adéquates.
          Accompagnement et suivi des sinistres en vue d'obtenir vos indemnités.
        </>
          )}
    />

    <PolicyItem
      icon={<i className="far fa-info-circle" />}
      description={(
        <>
          <Banner className="mt-3 cursor-pointer" link="/storage/IPID_AIDEJURIDIQUE.pdf" label="Voir la fiche produit" />
        </>
    )}
    />

    <p className="mt-3 cursor-pointer" link="/storage/IPID_BATEAU.pdf" label="Plus de détails" />
  </>
);
