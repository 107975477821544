import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <div className="grid grid-cols-1 mt-6 pt-6 border-t border-gray-200 text-gray-800 text-xs">
    <div>
      <span className="uppercase tracking-wider font-bold">Ce qui est compris</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Une « franchise anglaise » qui prévoit que la franchise ne soit pas appliquée dès que le montant des dégâts est supérieur à cette franchise.
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Le remboursement des dommages causés aux objets empruntés ou loués et les biens immobiliers prêtés ou loués.
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Des montants d'intervention doublés en Protection juridique Vie Privée.
        </div>
      </div>
    </div>
    <div className="mt-6">
      <span className="uppercase tracking-wider font-bold">Quels sont les dommages non couverts?</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Les dommages aux véhicules automoteurs soumis à l'assurance responsabilité civile auto.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Les dommages causés par un acte intentionnel d'un assuré.
        </div>
      </div>
    </div>
  </div>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ do_lend: !selected })}
          k="do_lend"
          label="Extension prêts & locations"
          description="Couverture des dommages matériels aux bien confiés. Bonus: pas de franchise si le montant du sinistre est supérieur à la franchise."
          selected={selected}
          price={`${price}€/m`}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
