import React from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import ChatBox from '@components/chat/ChatBox';

function Step({ updateForm, nextStepPath, form }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'optionValue', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: form.has_tier, // default value before the render
  });

  const submitStep = () => {
    updateForm({ has_tier: optionValue }, nextStepPath);
  };

  const selectedOptions = [
    { k: 'true', label: 'Oui', selected: (optionValue === 'true') },
    { k: 'false', label: 'Non', selected: (optionValue === 'false') },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">Vous, ou un membre de votre famille conduisez-vous régulièrement un véhicule de tiers?</p>
      </div>
      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options
          options={selectedOptions}
          onChange={(value) => setValue('optionValue', value)}
        />
      </div>
      <div className="mt-6">
        <ChatBox
          showAvatar
          content={(
            <>
              La conduite de véhicule de tiers ?
              <p>Pour le ménage qui n’a aucun véhicule en nom propre et qui : Est adepte de l’économie de partage, ou dispose uniquement d’un véhicule de leasing ou d’un véhicule de société, ou loue un véhicule lors de vacances à l’étranger.</p>
              <p className="mt-2">Cette garantie est automatiquement incluse dès la souscription de 1 véhicule.</p>
            </>
            )}
        />
      </div>
      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.has_tier}
          {...register('optionValue', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
