import React from 'react';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import {
  Link,
} from 'react-router-dom';

function Welcome({ nextStepPath }) {
  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <h1 className="text-gradient-primary text-8xl tracking-tight font-black mt-6">Hello.</h1>
      <div className="mt-6">
        <p className="font-serif text-4xl text-gray-800">
          Je suis Ambre,
          <br />
          ta conseillère!
        </p>
      </div>
      <div className="mt-6">
        <ChatBox
          showAvatar
          content={(
            <>
              <div>Avant de commencer je voudrais expliquer un point important:</div>

              <div classNe="mt-2">
                Ce contrat bénéficie d'un avantage fiscal important!
                <br />
                C'est-à-dire que le montant de la prime est récupérable à hauteur de 40 % (avec un plafond de 124 €)
                <br />
                <p className="italic">Exemple : pour une prime de 280 € pour récupérer 112 € l'année prochaine via la déclaration fiscale.</p>
              </div>

              <div className="mt-2">Finalement la prime ne vous coûte que 124 € par an pour une protection ultra large 👍</div>

              <div className="mt-2">Assez parlé commençons notre entretien conseil.</div>
            </>
)}
        />
      </div>
      <div className="mt-16">
        <Link to={nextStepPath}>
          <Button.Primary label="C'est parti" />
        </Link>
      </div>
    </div>
  );
}

export default Welcome;
