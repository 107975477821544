import React from 'react';
import OptionExcellence from './OptionExcellence';

const OptionSet1 = ({ setOption, form, summary }) => (
  <>
    <div className="grid gap-6 items-center justify-center">
      <OptionExcellence setOption={setOption} selected={form.with_excellence === 'true'} price={summary.options.with_excellence} />
    </div>
  </>
);

export default { set1: OptionSet1 };
