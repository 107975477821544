import React, {
  useState,
} from 'react';
import Dialog from '@components/dialog/Dialog';
import Banner from '@components/Banner';

export default function Option() {
  const [open, setOpen] = useState(false);

  const dialogContent = (
    <>
      <Banner className="mt-3" prefix="PDF" link="/storage/SYNDIC_Clauses_specifiques_ACP.pdf" label="Exclusions spécifiques" />
      <Banner className="mt-3" prefix="PDF" link="/storage/SYNDIC_RC_exploitation_-_Conditions_Générales_-_20200301.pdf" label="Conditions générales RC Exploitation" />
      <Banner className="mt-3" prefix="PDF" link="/storage/SYNDIC_RC_exploitation_-_fiche_IPID_-_20191001.pdf" label="Fiche produit RC Exploitation" />
      <Banner className="mt-3" prefix="PDF" link="/storage/SYNDIC_RC_Professionnelle_-_20200301.pdf" label="RC Professionnelle - Dispositions spécifiques" />
      <Banner className="mt-3" prefix="PDF" link="/storage/SYNDIC_RC_Professionnelle_-_fiche_IPID_-_20200301.pdf" label="Fiche produit RC Professionnelle" />
      <Banner className="mt-3" prefix="PDF" link="/storage/SYNDIC_4185452-02.2020-Lexique.pdf" label="Lexique" />
      <Banner className="mt-3" prefix="PDF" link="/storage/SYNDIC_4185455-10.2019-Dispositions-administratives.pdf" label="Dispositions administratives" />
    </>
  );

  return (
    <>
      <div className="inset-0 flex items-center justify-center flex-col">
        <p>Tous les détails 👇</p>
        <Banner className="mt-3 cursor-pointer" prefix="PDF" onClick={() => setOpen(true)} label="Conditions & fiches produits" />
      </div>
      <Dialog open={open} setOpen={setOpen} title="Conditions & fiches produits" content={dialogContent} />
    </>
  );
}
