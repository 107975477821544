import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <div className="grid grid-cols-1 mt-6 pt-6 border-t border-gray-200 text-gray-800 text-xs">
    <div>
      <span className="uppercase tracking-wider font-bold">Ce qui est compris</span>
      <div className="mt-3 space-y-2">
        <div>En cas d’accident du travail de l'un de vos employés de maison, votre assurance Gens de Maison prévoit :</div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Le remboursement des frais médicaux ainsi que les frais pharmaceutiques et hospitaliers.
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          le versement d’indemnités en cas d’incapacité de travail (temporaire ou permanente)
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Le versement d’une indemnité en cas de décès et le remboursement des frais funéraires.
        </div>
      </div>
    </div>
    <div className="mt-6">
      <span className="uppercase tracking-wider font-bold">Quels sont les dommages non couverts?</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Les accidents qui se produisent au cours de la vie privée de ces personnes.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Les activités réalisées indépendamment de vous parce qu’elles requièrent une expérience spécifique ou du matériel professionnel, ce qui implique qu’elles ne peuvent être réalisées sous l'autorité du preneur d'assurance.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Les travailleurs employés par l'assuré dans le cadre de l'activité professionnelle de ce dernier.
        </div>
      </div>
    </div>
  </div>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ do_gens_maison: !selected })}
          k="do_lend"
          label="Extension Personnel de maison"
          description="Couverture en cas d’accident du travail de l'un de vos employés de maison, votre assurance Gens de Maison."
          selected={selected}
          price={`${price}€/m`}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
