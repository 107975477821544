import React from 'react';
import StepWelcome from './StepWelcome';
import StepName from './StepName';
import StepRegistered from './StepRegistered';
import StepSkiing from './StepSkiing';
import StepPower from './StepPower';
import StepPrivate from './StepPrivate';
import StepProtectionJuridique from './StepProtectionJuridique';
import Loader from './Loader';
import Order from './Order';
import Confirm from './Confirm';
import Thanks from '../../Thanks';

export default {
  steps: [<StepWelcome />, <StepName />, <StepRegistered />, <StepSkiing />, <StepPower />, <StepPrivate />, <StepProtectionJuridique />],
  Loader,
  Order,
  Confirm,
  Thanks,
};
