import React from 'react';
import StepWelcome from './StepWelcome';
import StepName from './StepName';
import StepRegistered from './StepRegistered';
import StepConstructionYear from './StepConstructionYear';
import StepShell from './StepShell';
import StepSkiing from './StepSkiing';
import StepPower from './StepPower';
import StepLength from './StepLength';
import StepPrototype from './StepPrototype';
import StepPrivate from './StepPrivate';
import StepType from './StepType';
import StepBelgianResident from './StepBelgianResident';
import Loader from './Loader';
import Order from './Order';
import Confirm from '../../Confirm';
import Thanks from '../../Thanks';

export default {
  steps: [<StepWelcome />, <StepName />, <StepType />, <StepRegistered />, <StepConstructionYear />, <StepShell />, <StepSkiing />, <StepPower />, <StepLength />, <StepPrototype />, <StepPrivate />, <StepBelgianResident />],
  Loader,
  Order,
  Confirm,
  Thanks,
};
