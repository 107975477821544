import React from 'react';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import {
  Link,
} from 'react-router-dom';

function Welcome({ nextStepPath }) {
  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <h1 className="text-gradient-primary text-8xl tracking-tight font-black">Hello.</h1>
      <div className="mt-6">
        <p className="font-serif text-4xl text-gray-800">
          Je suis Ambre,
          <br />
          ta conseillère!
        </p>
      </div>
      <div className="mt-6">
        <ChatBox
          showAvatar
          content={(
            <>
              <div>Avant de commencer je souhaite te préciser un point important:</div>

              <div className="mt-2">
                La prime est fiscalement déductible à titre de charge professionnelle.
                <br />
                Déjà une assez bonne nouvelle ! 😊
              </div>

              <div className="mt-2">Commençons notre entretien-conseil.</div>
            </>
)}
        />
      </div>
      <div className="mt-16">
        <Link to={nextStepPath}>
          <Button.Primary label="C'est parti" />
        </Link>
      </div>
    </div>
  );
}

export default Welcome;
