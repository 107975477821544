const PostConfirm = (payload, product) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };

  return fetch(`${process.env.REACT_APP_API_URL}/quote/${product}/submit`, requestOptions)
    .then((response) => response.json());// .then(data => console.log(data.id)
};

export default PostConfirm;
