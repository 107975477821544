import React from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import { useForm, useFormState, useWatch } from 'react-hook-form';
import ChatBox from '@components/chat/ChatBox';

function Step({ updateForm, nextStepPath, form }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'water_skiing', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: form.water_skiing, // default value before the render
  });

  const submitStep = (data) => {
    updateForm(data, nextStepPath);
  };

  const selectedOptions = [
    { k: 'true', label: 'Oui', selected: (optionValue === 'true') },
    { k: 'false', label: 'Non', selected: (optionValue === 'false') },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">Souhaites tu tracter un skieur ou une bouée ?</p>
      </div>
      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options
          options={selectedOptions}
          onChange={(value) => setValue('water_skiing', value)}
        />
      </div>
      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.water_skiing}
          {...register('water_skiing', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
