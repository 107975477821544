import React, { useState } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ form, updateForm, nextStepPath }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'optionValue',
    defaultValue: form.vehicule,
  });

  const submitStep = () => {
    updateForm({ vehicule: optionValue }, nextStepPath);
  };

  const selectedOptions = [
    { k: '0', label: 'Non', selected: (optionValue === '0') },
    { k: '1', label: '1', selected: (optionValue === '1') },
    { k: '2', label: '2', selected: (optionValue === '2') },
    { k: '2_plus', label: '+ de 2', selected: (optionValue === '2_plus') },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">
          Voulez-vous inclure la défense de vos intérêts lors de l'usage de votre (vos) véhicule(s) vous appartenant ?
        </p>
        <p className="font-serif text-2xl mt-6">Si oui, combien de voitures possédez vous?</p>
      </div>

      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options
          options={selectedOptions}
          onChange={(value) => setValue('optionValue', value)}
        />
      </div>

      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.vehicule}
          {...register('optionValue', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
