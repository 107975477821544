import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      icon={<i className="far fa-user-injured" />}
      title="Protection des dommages causées à autrui"
      description={(
        <>
          La couverture est acquise à concurrence de € 2.500.000,00 en dommages corporels et en dommages matériels
          (par exemple suite à une mauvaise manœuvre de ta part).
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-building" />}
      title="Quand le contrat s'applique"
      description={(
        <>
          Les garanties du contrat s’appliquent :
          <ul className="list-inside list-disc">
            <li>en cours de navigation du bateau</li>
            <li>durant son séjour à flot</li>
            <li>lors de son transport par route ou par voie ferrée</li>
            <li>lors des opérations de mise à l’eau ou de sortie de l’eau</li>
            <li>durant son séjour en garage et en tous lieux d’entretien, hivernage ou stationnement</li>
          </ul>
        </>
  )}
    />
    <PolicyItem
      icon={<i className="far fa-globe-europe" />}
      title="Etendue territoriale"
      description={(
        <>
          Les eaux intérieures et les mers bordants le Benelux, l’Allemagne, la France, Le Royaume Unie et la République Irlande, dans les limites de navigation au nord du 47° Latitude Nord et au sud de 54° Latitude Nord et à l’ouest de 7° Latitude Ouest.
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-medal" />}
      title="Service Steylemans"
      description={(
        <>
          Diagnostic de vos risques, et proposition des garanties adéquates.
          Accompagnement et suivi des sinistres en vue d'obtenir vos indemnités.
        </>
      )}
    />
  </>
);
