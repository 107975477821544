import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <></>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ with_excellence: (selected) ? 'false' : 'true' })}
          k="with_excellence"
          label="Garantie Excellence"
          description={(
            <>
              Vous
              {' '}
              <span className="font-bold">doublez des limites</span>
              {' '}
              d'intervention et
              {' '}
              <span className="font-bold">réduisez de moitié</span>
              {' '}
              les
              {' '}
              <span className="font-bold">délais</span>
              {' '}
              d'attente et
              {' '}
              <span className="font-bold">seuils</span>
              {' '}
              d'intervention.
              <p className="mt-2 italic">
                Exemple: En cas de litige en matière de droit du travail les garantie sont modifié comme suit
                seuil : 500€ devient 250 - délai d'attente : 12 mois devient 6 mois- Plafond de garantie : 8.000€ devient 16.000 €"
              </p>
            </>
            )}
          selected={selected}
          price={`${price}€/m`}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
