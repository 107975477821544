import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Ivresse"
      description={(
        <>
          L’ivresse ou l’état analogue et les violences sur personnes si l’assuré responsable a plus de 18 ans au moment des faits.
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Dommage à vos biens"
      description={(
        <>
          Les dommages causés à vos propres biens ou à un autre membre de la famille.
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Assurance obligatoire"
      description={(
        <>
          Les dommages qui doivent être couverts par une assurance légalement obligatoire (par ex. RC Auto, Incendie, ... sauf ce qui est explicitement couvert).
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Activité professionnelle"
      description={(
        <>
          Les sinistres qui découlent d’activités professionnelles.
        </>
      )}
    />
  </>
);
