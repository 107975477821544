import React from 'react';

function Wrapper({ className, children }) {
  return (
    <div
      className={`w-full mt-32 m-auto max-w-3xl flex items-center justify-center flex-col ${className} mb-3`}
    >
      <div className="px-6 md:px-0">
        {children}
      </div>
    </div>
  );
}

export default Wrapper;
