import React from 'react';
import Avatar from '@components/avatar/Avatar';

export default ({ showAvatar, content }) => (
  <div className="block flex flex-col-reverse md:flex-row items-center justify-items-end md:items-end mt-6 w-full">

    {showAvatar && <div className="mt-6"><Avatar size="xs" /></div>}
    {!showAvatar && <div className="md:h-12 md:w-12" />}
    <div className="bg-gray-50 rounded-lg p-6 text-sm text-dark md:ml-6 text-left flex-grow">
      { content }
    </div>
  </div>
);
