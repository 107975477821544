const stepValidator = (history, form, step, config) => {
  if (step === 1) return null;

  const previousSteps = Array.apply(null, Array(step));
  let redirectTo = null;
  previousSteps.some((val, key) => {
    config.stepValidations[key].forEach((elt) => {
      if (!form[elt]) {
        redirectTo = `${config.path}/start/${key}`;
        return true;
      }
    });
  });

  return redirectTo;
};

export default stepValidator;
