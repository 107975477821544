import React from 'react';
import Wrapper from '@components/Wrapper';
import { Link } from 'react-router-dom';

function Thanks({ pathToHome }) {
  return (
    <Wrapper>
      <h1 className="text-gradient-primary text-8xl tracking-tight font-bold mt-6">Merci.</h1>
      <p className="font-serif text-3xl text-gray-800 mt-6">
        J'ai bien reçu votre demande,
        <br />
        La demande est en en cours d'acception à la compagnie.
        <br />
        Nous vous reviendrons au plus vite.
      </p>
      <p className="flex items-center flex-col">
        <Link to={pathToHome} className="flex text-sm items-center justify-start text-gray-600 mt-6">
          <i className="fal fa-redo mr-3" />
          Nouvelle demande
        </Link>
        <a href="https://steylemansassurances.be/" className="flex text-sm items-center justify-start text-gray-600 mt-3">
          <i className="fal fa-external-link mr-3" />
          Ton courtier digital
        </a>
      </p>
    </Wrapper>
  );
}

export default Thanks;
