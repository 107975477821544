import React from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ updateForm, nextStepPath, form }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'optionValue', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: form.do_gens_maison, // default value before the render
  });

  const submitStep = () => {
    updateForm({ do_gens_maison: optionValue }, nextStepPath);
  };

  const selectedOptions = [
    { k: 1, label: 'Oui', selected: (optionValue === 1) },
    { k: 0, label: 'Non', selected: (optionValue === 0) },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">Un(e) aide ménagère, un jardinier, un(e) baby-sitter travaille pour toi ou ta famille dans le cadre privé, et qui est rémunéré ?</p>
      </div>
      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options
          options={selectedOptions}
          onChange={(value) => setValue('optionValue', value)}
        />
      </div>
      <div className="mt-6">
        <ChatBox showAvatar content={(<>Si tu rémunères par titres-services ou chèques ALE, cette obligation légale n'est pas applicable : c'est à la société qui emploie ces personnes de les assurer en cas d'accidents du travail.</>)} />
      </div>
      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.do_rent_car}
          {...register('optionValue', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
