import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <div className="grid grid-cols-1 mt-6 pt-6 border-t border-gray-200 text-gray-800 text-xs">
    <div>
      <span className="uppercase tracking-wider font-bold">Ce qui est compris</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Indemnisation des frais de défense pénale.
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Indemnisation des frais de recours civil.
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Indemnisation des frais  de recours
          en cas d’erreur médicale (jusqu’à 90 000 euros).
        </div>
      </div>
    </div>
    <div className="mt-6">
      <span className="uppercase tracking-wider font-bold">Quels sont les dommages non couverts?</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          La garantie "Recours civil à la suite d'une erreur médicale" ne s'applique pas aux traitements d'ordre esthétique sauf s'il s'agit d'une chirurgie réparatrice.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Les garanties "Recours civil" et "Recours civil à la suite d'une erreur médicale" ne s'appliquent pas si le sinistre est la conséquence d'une des fautes lourdes suivantes : l'assuré se trouve en état d'ivresse ou d'intoxication alcoolique punissable, ou se trouve dans un état analogue résultant de l'utilisation d'autres produits.
        </div>
      </div>
    </div>
  </div>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ with_protection_juridique: (selected) ? 'false' : 'true' })}
          k="protection_juridique"
          label="Protection juridique"
          description="Indemnisation des frais de défense pénale, de recours civil et de recours en cas d'erreur médicale (jusqu'à 90000€)."
          selected={selected}
          price={`${price}€/m`}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
