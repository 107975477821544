import React, { Fragment } from 'react';
import classNames from 'classnames';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

const Input = (props) => {
  const {
    label, placeholder, type, error, register, name, rules, defaultValue, min,
  } = props;
  return (
    <div className="w-full">
      <label className="sr-only">
        {label}
      </label>
      <input
        placeholder={placeholder}
        type={type}
        {...register(name, { ...rules })}
        defaultValue={defaultValue}
        min={min}
        className={classNames({
          'bg-gray-50 px-5 py-3 w-full focus:outline-none rounded-md': true,
          'border-red-300 text-red-900 placeholder-red-300 border': error,
        })}
      />
    </div>
  );
};

const Textarea = (props) => {
  const {
    label, placeholder, type, error, register, name, rules, defaultValue, min,
  } = props;
  return (
    <div className="w-full">
      <label className="sr-only">
        {label}
      </label>
      <textarea
        placeholder={placeholder}
        {...register(name, { ...rules })}
        defaultValue={defaultValue}
        className={classNames({
          'bg-gray-50 px-5 py-3 w-full focus:outline-none rounded-md': true,
          'border-red-300 text-red-900 placeholder-red-300 border': error,
        })}
      />
    </div>
  );
};

const Checkbox = (props) => {
  const {
    label, error, register, name, rules,
  } = props;
  return (
    <div className="relative flex items-start justify-start mt-6">
      <div className="flex items-center h-5">
        <input
          {...register(name, { ...rules })}
          id={name}
          name={name}
          type="checkbox"
          className={classNames({
            'focus:ring-indigo-500 h-4 w-4 rounded': true,
            'text-indigo-600 border-gray-300 ': !error,
            'border-red-300': error,
          })}
        />
      </div>
      <div className="ml-3 text-sm">
        <label
          htmlFor={name}
          className={classNames({
            'text-left block font-base': true,
            'text-gray-500': !error,
            'text-red-300': error,
          })}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

const Option = (props) => {
  const {
    k, label, icon, selected, onChange,
  } = props;
  return (
    <div className="flex justify-around">
      <div
        role="button"
        onClick={() => onChange(k)}
        className={`relative h-20 bg-gray-100 rounded-xl w-24 text-xs uppercase text-gray-600 font-medium flex flex-col items-center justify-center cursor-pointer text-sm
        ${(selected) ? 'border-2 border-blue-500 form__options__option__active text-blue-500' : ''}
        ${(icon) ? 'w-32 h-32' : ''}
        `}
      >
        { (icon) ? <div className="block mb-1 text-2xl">{icon}</div> : ''}
        {label}
        { (selected) ? (<div className="rounded-full bg-blue-500 h-6 w-6 text-white flex items-center justify-center -top-2 -right-2 absolute"><i className="far fa-check" /></div>) : ''}
      </div>
    </div>
  );
};

const OptionLarge = (props) => {
  const {
    k, label, description, selected, onChange, price, moreInfo,
  } = props;
  return (
    <div
      role="button"
      onClick={() => onChange(k)}
      className={classNames({
        'relative p-8 w-full bg-gray-100 rounded-xl w-24 text-xs text-gray-600 font-medium flex flex-col text-left items-left justify-start cursor-pointer border-2 border-gray-100': true,
        'border-blue-500 form__options__option__active text-blue-500': selected,
        '': !selected,
      })}
    >

      <div className="flex justify-between items-center mb-3">
        <div>
          {price && (
          <div className={classNames({
            'rounded-full px-2 py-1 text-white inline-block md:flex items-center justify-center ': true,
            'bg-blue-500': selected,
            'bg-gray-300': !selected,
          })}
          >
            <span>{price}</span>
          </div>
          )}
        </div>
        {(selected) ? <i className="far fa-toggle-on text-2xl" /> : <i className="far fa-toggle-off text-2xl" />}
      </div>

      <div className="uppercase font-bold mb-2 text-base">{label}</div>
      <div className="text-gray-800 text-sm">{description}</div>
      { (selected === true || selected === 1) && moreInfo }
    </div>
  );
};

const Options = (props) => {
  const {
    options, type, style, onChange,
  } = props;
  return (
    <div className={`gap-6 items-center justify-center
      ${(!style) ? ' grid ' : ''}
      ${(style) || ''}
      ${(type && type === 'large') ? 'w-full grid-cols-1' : 'grid-cols-2'}
    `}
    >
      { (options.map((option) => ((type && type === 'large') ? (<OptionLarge key={option.k} {...option} onChange={onChange} />) : (<Option key={option.k} {...option} onChange={onChange} />))))}
    </div>
  );
};

const Select = (props) => {
  const {
    selected, setSelected, options, placeHolder,
  } = props;
  return (
    <Listbox value={selected} onChange={setSelected}>
      {({ open }) => (
        <>
          <div className="mt-1 relative">
            <Listbox.Button className="text-left bg-gray-50 w-full px-8 py-4 rounded-xl relative w-full cursor-default focus:outline-none focus:ring-1 focus:ring-primary focus:border-primary">
              <span className="block truncate">
                {(selected && selected.name) ? selected.name : <span className="text-gray-400">{ placeHolder }</span>}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base text-left ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) => classNames(
                      active ? 'text-white bg-primary' : 'text-gray-900',
                      'cursor-default select-none relative py-2 pl-3 pr-9',
                    )}
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate text-base')}>
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-primary',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};

const Radio = (props) => {
  const {
    label, error, register, name, rules, value, defaultValue, checked,
  } = props;
  return (
    <div className="relative flex justify-center items-center">
      <div className="flex items-center justify-center h-5">
        <input
          {...register(name, { ...rules })}
          value={value}
          id={name}
          name={name}
          type="radio"
          checked={checked}
          className={classNames({
            'focus:ring-indigo-500 h-4 w-4 rounded': true,
            'text-indigo-600 border-gray-300 ': !error,
            'border-red-300': error,
          })}
        />
      </div>
      <div className="ml-3 text-md">
        <label
          htmlFor={name}
          className={classNames({
            'text-left block font-base': true,
            'text-gray-500': !error,
            'text-red-300': error,
          })}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

const validateRequired = (value) => value && value.length > 0;

const Form = {
  Input,
  Checkbox,
  Options,
  Option,
  Textarea,
  Radio,
  OptionLarge,
  Select,
  validateRequired,
};

export default Form;
