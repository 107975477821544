import React, {
  useState,
} from 'react';
import Dialog from '@components/dialog/Dialog';
import Banner from '@components/Banner';

export default function Option() {
  const [open, setOpen] = useState(false);

  const dialogContent = (
    <>
      <Banner className="mt-3" prefix="PDF" link="/storage/CG_BATEAU.pdf" label="Conditions générales" />
      <Banner className="mt-3" prefix="PDF" link="/storage/IPID_BATEAU.pdf" label="Fiche produit" />
    </>
  );
  return (
    <>
      <div className="inset-0 flex items-center justify-center flex-col">
        <p>Tous les détails 👇</p>
        <Banner className="mt-3 cursor-pointer" prefix="PDF" onClick={() => setOpen(true)} label="Conditions & fiches produits" />
      </div>
      <Dialog open={open} setOpen={setOpen} title="Conditions & fiches produits" content={dialogContent} />
    </>
  );
}
