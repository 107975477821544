import React, { useState } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ form, updateForm, nextStepPath }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'optionValue',
    defaultValue: form.do_lend,
  });

  const submitStep = () => {
    updateForm({ do_lend: optionValue }, nextStepPath);
  };

  const selectedOptions = [
    { k: 1, label: 'Oui', selected: (optionValue === 1) },
    { k: 0, label: 'Non', selected: (optionValue === 0) },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">
          Désires tu assurer les dommages causés aux objets empruntés ou loués?
        </p>
      </div>

      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options options={selectedOptions} onChange={(value) => setValue('optionValue', value)} />
      </div>

      <div className="mt-6">
        <ChatBox showAvatar content={(<>En bonus, pas de franchise si le montant du sinistre est supérieur à la franchise. (267,41€* avril 2021)!</>)} />
      </div>

      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.do_lend}
          {...register('optionValue', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
