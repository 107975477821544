import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      icon={<i className="far fa-user-injured" />}
      title="Responsabilité civile (RC)"
      description={(
        <>
          Pour les dommages causés au tiers,
          par vous-même ainsi que par :
          toute personne qui, à titre récréatif, participe à la conduite
          du bateau, du chef d’accidents causés par le bateau en
          activité ou au repos
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-window-frame" />}
      title="Responsabilité civile (RC) Capitaux assuré"
      description={(
        <>
          La couverture est acquise à concurrence de € 2.500.000,00 en dommages corporels et en dommages matériels.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-balance-scale" />}
      title=" Quand le contrat s'applique"
      description={(
        <>
          Les garanties du contrat s’appliquent :
          – en cours de navigation du bateau
          – durant son séjour à flot
          – lors de son transport par route ou par voie ferrée
          – lors des opérations de mise à l’eau ou de sortie de l’eau
          – durant son séjour en garage et en tous lieux d’entretien,
          hivernage ou stationnement
        </>
  )}
    />
    <PolicyItem
      icon={<i className="far fa-building" />}
      title="Protection juridique"
      description={(
        <>
          o Légal village : prévention et information
          o défense pénale et recours civil (50.000 EUR)
          o insolvabilité du tiers responsable (6.200 EUR)
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-address-card" />}
      title="Etendue de base"
      description={(
        <>
          ZONE 1 : sur les eaux intérieures et les mers bordants le Benelux, l’Allemagne, la France, Le Royaume Unie et la République Irlande, dans les limites de navigation au nord du 47° Latitude Nord et au sud de 54° Latitude Nord et à l’ouest de 7° Latitude Ouest.
        </>
      )}
    />

  </>
);
