import React from 'react';
import Form from '@components/form/Form';

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ has_council: (selected) ? 'false' : 'true' })}
          k="has_auditor"
          label="Conseil de copropriété"
          description="Le conseil est chargé de veiller à la bonne exécution par le syndic de ses missions à l'exception de la vérification de la comptabilité."
          selected={selected}
        />
      </div>
    </>
  );
}
