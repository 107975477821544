import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Dommages causés intentionnellement"
      description={(<>Est la volonté de causer un dommage.</>)}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Manquements"
      description={(
        <>
          Dommages résultant d’un manquement aux normes de prudence
          ou de sécurité ayant des conséquences dommageables inévitables,
          dommages répétés en raison de l’absence de mesures de
          précaution, dommages résultant d’un état d’ivresse…
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Sous-traitants"
      description={(
        <>
          Responsabilité des actes de sous-traitants et responsabilité des
          sous-traitants.
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Assurances obligatoires"
      description={(
        <>
          L’absence de souscription d’une assurance légalement obligatoire ainsi que l’absence ou l’insuffisance flagrante d’assurances couvrant les biens faisant l’objet de leur mission.
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="RC extra-contractuelle"
      description={(
        <>
          La RC extra-contractuelle du fait de dommages causés aux parties communes de la copropriété.
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      title="Non-conformité"
      description={(
        <>
          Les réclamations fondées sur ou en relation (directe ou indirecte) à la non-conformité des  bâtiments à la réglementation en vigueur en matière de sécurité des biens ou des personnes.
        </>
      )}
    />
  </>
);
