import React, { useContext, useEffect } from 'react';
import Form from '@components/form/Form';
import Wrapper from '@components/Wrapper';
import Button from '@components/form/Button';
import { useHistory, Link } from 'react-router-dom';
import { useForm, useFormState } from 'react-hook-form';
import ConfiguratorContext from '@containers/ConfiguratorContext';
import ApiPostConfirm from '../api/post-confirm';
import stepValidator from '../utils/stepValidator';

function Confirm({
  form, pathToOrder, pathToThanks, summary,
}) {
  const {
    register, handleSubmit, formState: { errors }, control,
  } = useForm({
    mode: 'onChange',
  });
  const configContext = useContext(ConfiguratorContext);
  const history = useHistory();

  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    stepValidator(history, form, configContext.config.stepsCount, configContext.config);
  }, []);

  useEffect(() => {
    // Include the Crisp code here, without the <script></script> tags
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'd9b196c9-ca96-4c3d-8dd1-a805bbaccbed';

    (function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    }());
  }, []);

  const postForm = (data) => {
    const payload = { ...data, form, price: summary.price };
    ApiPostConfirm(payload, form.product)
      .then(() => {
        history.push(pathToThanks);
      })
      .catch(() => {
      });
  };

  return (
    <Wrapper className="text-center flex justify-center flex-col items-center px-6">
      <div className="flex justify-center">
        <Link to={pathToOrder} className="hidden md:flex text-sm items-center justify-start text-primary rounded-full bg-gray-50 w-12 h-12 justify-center">
          <i className="fal fa-arrow-left" />
        </Link>
      </div>
      <p className="font-serif text-3xl text-gray-800 mt-6">
        Votre offre est prête à être soumise! Nous vous envoyons les documents de souscription par email dans les 24h.
      </p>
      <form className="mt-10 gap-3 flex flex-col" onSubmit={handleSubmit(postForm)}>
        <Form.Input
          type="text"
          name="first_name"
          register={register}
          rules={{ required: true }}
          defaultValue={form.first_name}
          autoFocus
          label="Prénom"
          placeholder="Prénom"
          error={errors.first_name}
        />
        <Form.Input
          type="text"
          name="last_name"
          register={register}
          rules={{ required: true }}
          defaultValue={form.last_name}
          autoFocus
          label="Nom"
          placeholder="Nom"
          error={errors.last_name}
        />
        <Form.Input
          type="email"
          name="email"
          register={register}
          rules={{ required: true }}
          defaultValue={form.email}
          autoFocus
          label="Email"
          placeholder="Email"
          error={errors.email}
        />
        <Form.Input
          type="text"
          name="phone"
          register={register}
          rules={{ required: true }}
          defaultValue={form.phone}
          autoFocus
          label="Téléphone"
          placeholder="Téléphone"
          error={errors.phone}
        />

        <Form.Checkbox
          register={register}
          rules={{ required: true }}
          label={(
            <>
              Dans le cadre de ma demande d'information pour une assurance, je suis d'accord de recevoir par email mon éventuel futur contrat (
              <a href="https://steylemansassurances.be/mentions-legales/" target="_blank" rel="noreferrer">conditions générales</a>
              , et la
              {' '}
              <a href="https://steylemansassurances.be/regles-mifid/">notice d'information générale</a>
              ).
            </>
          )}
          name="terms"
          error={errors.terms}
        />

        <div className="mt-6">
          <Button.Primary label="Envoyer" type="submit" disabled={!isValid} />
        </div>
      </form>
    </Wrapper>
  );
}

export default Confirm;
