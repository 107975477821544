import React from 'react';
import Button from '@components/form/Button';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

export default ({
  price, features, pathToConfirm, billPeriod, children,
}) => {
  const featuresGroup = [];
  for (const key in features) {
    if (features.hasOwnProperty(key)) {
      featuresGroup.push(<li key={key} className={classNames({ 'flex items-start lg:col-span-1': true, 'opacity-50': !features[key].active })}>
        <div className="flex-shrink-0">
          { features[key].active && <i className="fa fa-check-circle text-green-400" /> }
          { !features[key].active && <i className="fa fa-times-circle text-gray-600" /> }
        </div>
        <p className="ml-3 text-left text-sm text-gray-700">
          {features[key].label}
        </p>
      </li>);
    }
  }

  return (
    <div className="mt-8 bg-white sm:mt-12">
      <div className="relative">
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-lg mx-auto rounded-xl shadow-lg overflow-hidden lg:max-w-none lg:flex">
            <div className="flex-1 bg-white px-6 py-8 lg:p-12 items-center m-auto">
              <ul role="list" className="space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5">
                {featuresGroup}
              </ul>
              {children}
            </div>
            <div
              className="py-8 px-6 text-center bg-gray-50 lg:flex-shrink-0 lg:flex lg:flex-col lg:justify-center lg:p-12"
            >
              <div className="mt-4 flex items-center justify-center text-4xl font-extrabold text-gray-900">
                <span>
                  {price}
                  €
                </span>
                <span className="ml-3 text-xl font-medium text-gray-500">
                  par
                  {' '}
                  {billPeriod}
                </span>
              </div>
              <div className="mt-6">
                <Link to={pathToConfirm}><Button.Primary size="md" label="Ça me convient" /></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
