import React, { useContext, useEffect, useState } from 'react';
import Form from '@components/form/Form';
import Wrapper from '@components/Wrapper';
import Button from '@components/form/Button';
import { useHistory, Link } from 'react-router-dom';
import { useForm, useFormState } from 'react-hook-form';
import ConfiguratorContext from '@containers/ConfiguratorContext';
import ApiPostConfirm from '../../../api/post-confirm';
import stepValidator from '../../../utils/stepValidator';

function Confirm({
  form, pathToOrder, pathToThanks, summary,
}) {
  const {
    register, handleSubmit, formState: { errors }, control,
  } = useForm({
    mode: 'onChange',
  });
  const configContext = useContext(ConfiguratorContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    stepValidator(history, form, configContext.config.stepsCount, configContext.config);
  }, []);

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'd9b196c9-ca96-4c3d-8dd1-a805bbaccbed';

    (function () {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    }());
  }, []);

  const postForm = (data) => {
    const payload = { subscription_form: data, form, price: summary.price };
    setIsLoading(true);
    ApiPostConfirm(payload, form.product)
      .then(() => {
        history.push(pathToThanks);
      })
      .catch(() => {
      });
  };

  return (
    <Wrapper className="text-center flex justify-center flex-col items-center px-6">
      <div className="flex justify-center">
        <Link to={pathToOrder} className="hidden md:flex text-sm items-center justify-start text-primary rounded-full bg-gray-50 w-12 h-12 justify-center">
          <i className="fal fa-arrow-left" />
        </Link>
      </div>
      <p className="font-serif text-3xl text-gray-800 mt-6">
        Votre demande de contrat est prête à être soumise!
        <br />
        Merci de compléter les éléments essentiels à sa réalisation.
      </p>
      <form className="mt-10 gap-3 flex flex-col" onSubmit={() => buttonRef.current.click()}>
        <div className="flex gap-3">
          <div className="w-2/3">
            <Form.Input
              type="text"
              name="acp_denomination"
              register={register}
              rules={{ required: false }}
              defaultValue={form.acp_denomination}
              autoFocus
              label="Dénomination ACP"
              placeholder="Dénomination ACP"
              error={errors.acp_denomination}
            />
          </div>
          <div className="w-1/3">
            <Form.Input
              type="text"
              name="acp_number"
              register={register}
              rules={{ required: false }}
              defaultValue={form.acp_number}
              autoFocus
              label="N°d'enregistrement ACP"
              placeholder="N°d'enregistrement ACP"
              error={errors.acp_number}
            />
          </div>
        </div>

        <div className="flex gap-3">
          <div className="w-1/2">
            <Form.Input
              type="text"
              name="acp_address_street"
              register={register}
              rules={{ required: false }}
              defaultValue={form.acp_address_street}
              autoFocus
              label="Rue"
              placeholder="Rue"
              error={errors.acp_address_street}
            />
          </div>
          <div className="flex w-1/2 gap-3">
            <Form.Input
              type="text"
              name="acp_address_number"
              register={register}
              rules={{ required: false }}
              defaultValue={form.acp_address_number}
              autoFocus
              label="Numéro"
              placeholder="Numéro"
              error={errors.acp_address_number}
            />
            <Form.Input
              type="text"
              name="acp_address_box"
              register={register}
              rules={{ required: false }}
              defaultValue={form.acp_address_box}
              autoFocus
              label="Boite"
              placeholder="Boite"
              error={errors.acp_address_box}
            />
          </div>
        </div>

        <div className="flex gap-3">
          <div className="w-2/3">
            <Form.Input
              type="text"
              name="acp_address_commune"
              register={register}
              rules={{ required: false }}
              defaultValue={form.acp_address_commune}
              autoFocus
              label="Commune"
              placeholder="Commune"
              error={errors.acp_address_commune}
            />
          </div>
          <div className="flex w-1/3 gap-3">
            <Form.Input
              type="text"
              name="acp_address_postalcode"
              register={register}
              rules={{ required: false }}
              defaultValue={form.acp_address_postalcode}
              autoFocus
              label="Code Postal"
              placeholder="Code Postal"
              error={errors.acp_address_postalcode}
            />
          </div>
        </div>

        <div className="flex gap-3">
          <Form.Input
            type="email"
            name="acp_email"
            register={register}
            rules={{ required: true }}
            defaultValue={form.acp_email}
            autoFocus
            label="Email"
            placeholder="Email"
            error={errors.acp_email}
          />
          <Form.Input
            type="text"
            name="acp_phone"
            register={register}
            rules={{ required: true }}
            defaultValue={form.acp_phone}
            autoFocus
            label="Téléphone"
            placeholder="Téléphone"
            error={errors.acp_phone}
          />
        </div>

        <div>
          <p className="mt-12 mb-3 font-serif text-xl text-gray-800">
            L’immeuble est-il conforme à la réglementation en vigueur en matière de sécurité des biens et/ou des personnes ? (électricité, incendie, …)
          </p>
          <div className="flex gap-x-8 items-center justify-center">
            <Form.Radio
              name="flag_is_conform"
              register={register}
              defaultValue={form.flag_is_conform}
              label="Oui"
              error={errors.flag_is_conform}
              value="true"
            />
            <Form.Radio
              name="flag_is_conform"
              register={register}
              defaultValue={form.flag_is_conform}
              label="Non"
              error={errors.flag_is_conform}
              value="false"
            />
          </div>
        </div>

        {(form.is_syndic_volunteer === 'true'
          && (
          <div className="gap-3 flex flex-col">
            <p className="mt-12 mb-3 font-serif text-xl text-gray-800">
              Quel est la personne responsable dans votre syndic?
            </p>

            <div className="flex gap-3">
              <Form.Input
                type="text"
                name="syndic_firstname"
                register={register}
                rules={{ required: false }}
                defaultValue={form.syndic_firstname}
                autoFocus
                label="Prénom"
                placeholder="Prénom"
                error={errors.syndic_firstname}
              />
              <Form.Input
                type="text"
                name="syndic_lastname"
                register={register}
                rules={{ required: false }}
                defaultValue={form.syndic_lastname}
                autoFocus
                label="Nom"
                placeholder="Nom"
                error={errors.syndic_lastname}
              />
            </div>
            <div className="flex gap-3">
              <Form.Input
                type="date"
                name="syndic_birth_date"
                register={register}
                rules={{ required: false }}
                defaultValue={form.syndic_birth_date}
                autoFocus
                label="Date de naissance"
                placeholder="Date de naissance"
                error={errors.syndic_birth_date}
              />
              <Form.Input
                type="text"
                name="syndic_nationality"
                register={register}
                rules={{ required: false }}
                defaultValue={form.syndic_nationality}
                autoFocus
                label="Nationalité"
                placeholder="Nationalité"
                error={errors.syndic_nationality}
              />
            </div>
            <div className="flex gap-3">
              <div className="w-1/2">
                <Form.Input
                  type="text"
                  name="syndic_address_street"
                  register={register}
                  rules={{ required: false }}
                  defaultValue={form.syndic_address_street}
                  autoFocus
                  label="Rue"
                  placeholder="Rue"
                  error={errors.syndic_address_street}
                />
              </div>
              <div className="flex w-1/2 gap-3">
                <Form.Input
                  type="text"
                  name="syndic_address_number"
                  register={register}
                  rules={{ required: false }}
                  defaultValue={form.syndic_address_number}
                  autoFocus
                  label="Numéro"
                  placeholder="Numéro"
                  error={errors.syndic_address_number}
                />
                <Form.Input
                  type="text"
                  name="syndic_address_box"
                  register={register}
                  rules={{ required: false }}
                  defaultValue={form.syndic_address_box}
                  autoFocus
                  label="Boite"
                  placeholder="Boite"
                  error={errors.syndic_address_box}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <div className="w-2/3">
                <Form.Input
                  type="text"
                  name="syndic_address_commune"
                  register={register}
                  rules={{ required: false }}
                  defaultValue={form.syndic_address_commune}
                  autoFocus
                  label="Commune"
                  placeholder="Commune"
                  error={errors.syndic_address_commune}
                />
              </div>
              <div className="flex w-1/3 gap-3">
                <Form.Input
                  type="text"
                  name="syndic_address_postalcode"
                  register={register}
                  rules={{ required: false }}
                  defaultValue={form.syndic_address_postalcode}
                  autoFocus
                  label="Code Postal"
                  placeholder="Code Postal"
                  error={errors.syndic_address_postalcode}
                />
              </div>
            </div>
            <div className="flex gap-3">
              <Form.Input
                type="email"
                name="syndic_email"
                register={register}
                rules={{ required: false }}
                defaultValue={form.syndic_email}
                autoFocus
                label="Email"
                placeholder="Email"
                error={errors.syndic_email}
              />
              <Form.Input
                type="text"
                name="syndic_phone"
                register={register}
                rules={{ required: false }}
                defaultValue={form.syndic_phone}
                autoFocus
                label="Téléphone"
                placeholder="Téléphone"
                error={errors.syndic_phone}
              />
            </div>

            <div className="text-left grid grid-cols-12 gap-3 items-center mt-12">
              <p className="col-span-8 font-serif text-lg text-gray-800">
                Le syndic est-il rémunéré ?
              </p>
              <div className="col-span-4 flex gap-x-8 items-center justify-start">
                <Form.Radio
                  name="flag_syndic_is_renum"
                  register={register}
                  defaultValue={form.flag_syndic_is_renum}
                  label="Oui"
                  error={errors.flag_syndic_is_renum}
                  value="true"
                />
                <Form.Radio
                  name="flag_syndic_is_renum"
                  register={register}
                  defaultValue={form.flag_syndic_is_renum}
                  label="Non"
                  error={errors.flag_syndic_is_renum}
                  value="false"
                />
              </div>

              <p className="col-span-8 font-serif text-lg text-gray-800">
                Quel est le montant de sa rémunération annuelle ?
              </p>
              <div className="col-span-4 flex gap-x-8 items-center justify-center">
                <Form.Input
                  type="text"
                  name="flag_syndic_remuneration"
                  register={register}
                  rules={{ required: false }}
                  defaultValue={form.flag_syndic_remuneration}
                  autoFocus
                  label="Rémunération"
                  placeholder="€"
                  error={errors.flag_syndic_remuneration}
                />
              </div>

              <p className="col-span-8 font-serif text-lg text-gray-800">
                Est-il copropriétaire de l’immeuble ?
              </p>
              <div className="col-span-4 flex gap-x-8 items-center justify-start">
                <Form.Radio
                  name="flag_syndic_is_copro"
                  register={register}
                  defaultValue={form.flag_syndic_is_copro}
                  label="Oui"
                  error={errors.flag_syndic_is_copro}
                  value="true"
                />
                <Form.Radio
                  name="flag_syndic_is_copro"
                  register={register}
                  defaultValue={form.flag_syndic_is_copro}
                  label="Non"
                  error={errors.flag_syndic_is_copro}
                  value="false"
                />
              </div>

              <p className="col-span-8 font-serif text-lg text-gray-800">
                A-t-il été désigné dans le règlement de copropriété ?
              </p>
              <div className="col-span-4 flex gap-x-8 items-center justify-start">
                <Form.Radio
                  name="flag_syndic_is_in_copro_rules"
                  register={register}
                  defaultValue={form.flag_syndic_is_in_copro_rules}
                  label="Oui"
                  error={errors.flag_syndic_is_in_copro_rules}
                  value="true"
                />
                <Form.Radio
                  name="flag_syndic_is_in_copro_rules"
                  register={register}
                  defaultValue={form.flag_syndic_is_in_copro_rules}
                  label="Non"
                  error={errors.flag_syndic_is_in_copro_rules}
                  value="false"
                />
              </div>

              <p className="col-span-8 font-serif text-lg text-gray-800">
                Sa désignation a-t-elle approuvée par la première assemblée générale des copropriétaires?
              </p>
              <div className="col-span-4 flex gap-x-8 items-center justify-start">
                <Form.Radio
                  name="flag_syndic_is_approved_in_first_assembly"
                  register={register}
                  defaultValue={form.flag_syndic_is_approved_in_first_assembly}
                  label="Oui"
                  error={errors.flag_syndic_is_approved_in_first_assembly}
                  value="true"
                />
                <Form.Radio
                  name="flag_syndic_is_approved_in_first_assembly"
                  register={register}
                  defaultValue={form.flag_syndic_is_approved_in_first_assembly}
                  label="Non"
                  error={errors.flag_syndic_is_approved_in_first_assembly}
                  value="false"
                />
              </div>

              <p className="col-span-8 font-serif text-lg text-gray-800">
                A-t-il été désigné par l’assemblée générale des copropriétaires?
              </p>
              <div className="col-span-4 flex gap-x-8 items-center justify-start">
                <Form.Radio
                  name="flag_syndic_is_approved_by_copro"
                  register={register}
                  defaultValue={form.flag_syndic_is_approved_by_copro}
                  label="Oui"
                  error={errors.flag_syndic_is_approved_by_copro}
                  value="true"
                />
                <Form.Radio
                  name="flag_syndic_is_approved_by_copro"
                  register={register}
                  defaultValue={form.flag_syndic_is_approved_by_copro}
                  label="Non"
                  error={errors.flag_syndic_is_approved_by_copro}
                  value="false"
                />
              </div>
            </div>
          </div>
          )
        )}

        <div>
          <p className="mt-12 mb-3 font-serif text-xl text-gray-800">
            D'autres informations ou remarques?
          </p>

          <Form.Textarea
            register={register}
            rules={{ required: false }}
            name="remarks"
            error={errors.remarks}
          />
        </div>

        <div>
          <p className="mt-12 mb-3 font-serif text-xl text-gray-800">
            Souhaitez-vous examiner le contrat d'assurance incendie ?
          </p>
          <div className="flex gap-x-8 items-center justify-center">
            <Form.Radio
              name="flag_want_fire_insurance"
              register={register}
              defaultValue={form.flag_want_fire_insurance}
              label="Oui"
              error={errors.flag_want_fire_insurance}
              value="true"
            />
            <Form.Radio
              name="flag_want_fire_insurance"
              register={register}
              defaultValue={form.flag_want_fire_insurance}
              label="Non"
              error={errors.flag_want_fire_insurance}
              value="false"
            />
          </div>

        </div>

        <Form.Checkbox
          register={register}
          rules={{ required: true }}
          label={(
            <>
              Dans le cadre de ma demande d'information pour une assurance, je suis d'accord de recevoir par email mon éventuel futur contrat (
              <a href="https://steylemansassurances.be/mentions-legales/" target="_blank" rel="noreferrer" className="underline">conditions générales</a>
              , la
              {' '}
              <a href="https://steylemansassurances.be/regles-mifid/" target="_blank" rel="noreferrer" className="underline">notice d'information générale</a>
              {' '}
              et notre
              {' '}
              <a href="https://steylemansassurances.be/application-terms/" target="_blank" rel="noreferrer" className="underline">disclaimer</a>
              ).
            </>
          )}
          name="terms"
          error={errors.terms}
        />
        <div className="mt-6">
          <Button.Primary label="Envoyer" type="button" delay disabled={!isValid} onClick={handleSubmit(postForm)} />
        </div>
      </form>
    </Wrapper>
  );
}

export default Confirm;
