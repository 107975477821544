import React, { useState } from 'react';
import classNames from 'classnames';

const Primary = function Button(props) {
  const {
    label, onClick, size, delay, forwardRef = null, type, arrow = true, forceIsLoading = false,
  } = props;
  const [isLoading, setIsLoading] = useState(forceIsLoading);

  const clickHandler = () => {
    if (isLoading) return;

    const waitFor = (delay) ? 500 : 0;
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      if (onClick) onClick();
    }, waitFor);
  };

  return (
    <button
      type={type || 'submit'}
      ref={forwardRef}
      onClick={clickHandler}
      className={classNames({
        'bg-gradient-primary rounded-full text-white shadow-none  transition focus:outline-none': true,
        'w-64 py-3 text-xl font-bold hover:shadow-lg': (!size || size === 'xl') && label.length > 0,
        'w-16 py-3 text-xl font-bold hover:shadow-lg': label.length === 0,
        'py-2 px-6 font-bold hover:shadow-md': size,
      })}
    >
      { (label.length > 0 && <span className="mr-2">{label}</span>)}
      { !isLoading && <i className="text-base fa fa-arrow-right" />}
      { isLoading && <i className="text-base fa fa-circle-notch fa-spin" />}
      {' '}

    </button>
  );
};

function Secondary(props) {
  const { label, icon, className } = props;
  return (
    <button
      {...props}
      className={`inline-flex items-center px-8 py-4 border border-transparent shadow-sm text-base leading-4 font-medium rounded-full text-gray-800 bg-white transition hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${className}`}
    >
      { icon }
      { label }
    </button>
  );
}

const Button = {
  Primary, Secondary,
};

export default Button;
