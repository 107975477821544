import React from 'react';
import rcfam from './rcfam/Components';
import syndic from './syndic/Components';
import bateau from './bateau/Components';
import jetski from './jetski/Components';
import aidejuridique from './aidejuridique/Components';
import starter from './starter/Components';

export default {
  rcfam,
  syndic,
  bateau,
  jetski,
  aidejuridique,
  starter,
};
