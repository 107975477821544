import React from 'react';

export default (props) => {
  const { link, prefix, label } = props;
  return (
    <div {...props}>
      <a
        target="_blank"
        href={link}
        className="inline-flex items-center text-white bg-gray-100 rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-800 text-gray-800"
        rel="noreferrer"
      >
        { prefix && (
        <span
          className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-primary rounded-full"
        >
          {prefix}
        </span>
        )}
        <span className="ml-4 text-sm py-0.5">{label}</span>
        <svg
          className="ml-2 w-5 h-5 text-gray-800"
          x-description="Heroicon name: solid/chevron-right"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clipRule="evenodd"
          />
        </svg>
      </a>
    </div>
  );
};
