import React from 'react';
import Step1 from './Step_1';
import Step2 from './Step_2';
import Step3 from './Step_3';
import Step4 from './Step_4';
import Step5 from './Step_5';
import Step6 from './Step_6';
import Loader from './Loader';
import Order from './Order';
import Confirm from './Confirm';
import Thanks from '../../Thanks';

export default {
  steps: [<Step1 />, <Step2 />, <Step3 />, <Step4 />, <Step5 />, <Step6 />],
  Loader,
  Confirm,
  Order,
  Thanks,
};
