import React, { useState } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ form, updateForm, nextStepPath }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'is_registered_belgium',
    defaultValue: form.is_registered_belgium,
  });

  const submitStep = (data) => {
    updateForm(data, nextStepPath);
  };

  const selectedOptions = [
    { k: 'true', label: 'Oui', selected: (optionValue === 'true') },
    { k: 'false', label: 'Non', selected: (optionValue === 'false') },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">
          Le bateau est-il immatriculé en Belgique ?
        </p>
      </div>

      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options options={selectedOptions} onChange={(value) => setValue('is_registered_belgium', value)} />
      </div>

      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.is_registered_belgium}
          {...register('is_registered_belgium', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
