import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      icon={<i className="far fa-home" />}
      title="Votre foyer"
      description={(
        <>
          Même si elles sont en kot ou séjournent dans un établissement de soins.
          <br />
          Les personnes qui s'occupent de vos enfants et de vos animaux sont également assurées.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-dog" />}
      title="Vos Animaux"
      description={(
        <>
          Les animaux domestiques et de compagnie sont couverts, même des buffles, des rats, des araignées, des chouettes ou des serpents.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-bicycle" />}
      title="Multimobilité"
      description={(
        <>
          Trottinette (électrique), skateboard ou vélo ? Laissez libre cours à votre créativité et déplacez-vous l'esprit tranquille.
        </>
  )}
    />
    <PolicyItem
      icon={<i className="far fa-house-day" />}
      title="Résidence de vacances"
      description={(
        <>
          Résidence de vacances, chambre d'hôtel et salle de fête. Rien ne peut gâcher votre plaisir. Lâchez-vous !.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-tennis-ball" />}
      title="Sport et détente"
      description={(
        <>
          Pas de souci, une ribambelle d’activités sont couvertes. Même les drones et jet-skis.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-hands-heart" />}
      title="Bénévolat & économie collaborative"
      description={(
        <>
          Rien ne peut vous empêcher d'aider les gens et de compléter votre salaire.
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-medal" />}
      title="Service Steylemans"
      description={(
        <>
          Diagnostic de vos risques, et proposition des garanties adéquates.
          Accompagnement et suivi des sinistres en vue d'obtenir vos indemnités.
        </>
      )}
    />

  </>
);
