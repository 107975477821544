import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '@components/form/Button';
import Wrapper from '@components/Wrapper';
import AnimatedServices from './policies/Services';

function Loader({ form, compute, pathToOrder }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [animation, setAnimation] = useState(false);

  useEffect(() => {
    compute();
    setAnimation(<AnimatedServices form={form} />);
    setTimeout(() => { setIsLoaded(true); }, 7000);
  }, []);

  return (
    <Wrapper>
      <h1 className="text-gradient-primary text-8xl tracking-tight font-bold mt-6">On a fini!</h1>
      <div className="mt-8">
        { (!isLoaded) ? <Button.Primary label="" forceIsLoading />
          : (
            <Link to={pathToOrder}><Button.Primary arrow={false} label="Voir ton offre" /></Link>
          )}
      </div>
      {animation}

    </Wrapper>
  );
}

export default Loader;
