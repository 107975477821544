import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <div className="grid grid-cols-1 mt-6 pt-6 border-t border-gray-200 text-gray-800 text-xs">
    <div>
      <span className="uppercase tracking-wider font-bold">Ce qui est compris</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Extension de l’assurance omnium en cas de location d’une voiture ou
          d’une camionnette. Couverture des dommages matériels (en ce compris les bris de vitrage,
          les dommages aux pneus ou au toit) jusqu’à 2.500 euros.
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Les sinistres causés par un
          conducteur qui ne dispose pas d’un permis
          valable pour conduire le véhicule loué dans le pays
          où est survenu le sinistre
        </div>
      </div>
    </div>
    <div className="mt-6">
      <span className="uppercase tracking-wider font-bold">Ce qui n'est pas compris</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start mt-">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Les dommages aux véhicules
          automoteurs soumis à l’assurance RC Auto, sauf
          ceux qui sont explicitement couverts.
        </div>
      </div>
    </div>
  </div>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ do_rent_car: !selected })}
          k="do_rent_car"
          label="Extension location voiture"
          description="Extension de l'assurance omnium en cas de location d'une voiture jusqu'à 2500€."
          selected={selected}
          price={`${price}€/m`}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
