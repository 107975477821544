import React from 'react';
import avatar from '@images/avatar.png';
import classNames from 'classnames';

function Avatar({ size }) {
  size = (size) || 'sm';
  return (
    <div className={classNames({
      'rounded-full overflow-hidden block': true,
      'h-32 w-32': (size === 'sm'),
      'h-12 w-12': (size === 'xs'),
      'h-38 w-38': (size === 'md'),
    })}
    >
      <img src={avatar} alt="avatar" />
    </div>
  );
}

export default Avatar;
