import React, { Suspense, lazy, useEffect } from 'react';
import {
  Switch,
  Route,
  useLocation,
  Redirect,
} from 'react-router-dom';
import {
  TransitionGroup,
  CSSTransition,
} from 'react-transition-group';
import ConfiguratorContext from '@containers/ConfiguratorContext';
import Form from './Form';
import rcfamConfig from './products/rcfam/config.json';
import syndicConfig from './products/syndic/config.json';
import bateauConfig from './products/bateau/config.json';
import jetskiConfig from './products/jetski/config.json';
import aidejuridiqueConfig from './products/aidejuridique/config.json';
import starterConfig from './products/starter/config.json';

import Products from './products';
import ProductIndex from './ProductIndex';

function Routes({
  form, setForm, summary, updateFormAndCompute, updateForm, compute,
}) {
  const location = useLocation();
  const config = loadConfig(location, [rcfamConfig, syndicConfig, bateauConfig, jetskiConfig, aidejuridiqueConfig, starterConfig]);

  const productPath = (config) ? config.path : '';
  const c = () => ('');
  let ProductComponents = {
    Loader: c, Order: c, Confirm: c, Thanks: c, Form: c,
  };
  if (config) {
    useEffect(() => {
      setForm(config.form);
    }, []);
    ProductComponents = (config) ? Products[config.key] : null;
  }

  return (
    <ConfiguratorContext.Provider value={{ config }}>
      <div className="configurator">
        <TransitionGroup component={null}>
          <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={500}
          >
            <Switch location={location}>
              <Route exact path="/">
                <ProductIndex />
              </Route>
              <Route exact path={`${productPath}/loading`}>
                <ProductComponents.Loader
                  form={form}
                  compute={compute}
                  updateForm={updateForm}
                  pathToOrder={`${productPath}/order`}
                  pathToConfirm={`${productPath}/confirm`}
                  summary={summary}
                />
              </Route>
              <Route exact path={`${productPath}/order`}>
                {summary !== null
                  ? (
                    <ProductComponents.Order
                      form={form}
                      summary={summary}
                      compute={(data) => updateFormAndCompute(data)}
                      pathToConfirm={`${productPath}/confirm`}
                    />
                  )
                  : <Redirect to={`${productPath}`} />}
              </Route>
              <Route exact path={`${productPath}/confirm`}>
                <ProductComponents.Confirm
                  form={form}
                  summary={summary}
                  updateForm={(data) => updateForm(data)}
                  pathToOrder={`${productPath}/order`}
                  pathToThanks={`${productPath}/thanks`}
                />
              </Route>
              <Route exact path={`${productPath}/thanks`}>
                <ProductComponents.Thanks pathToHome={`${productPath}`} />
              </Route>
              {productPath.length > 0
              && (
              <Route exact path={[`${productPath}/start/:id`, productPath]}>
                <Form form={form} updateForm={updateForm} productComponents={ProductComponents} />
              </Route>
              )}
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </div>
    </ConfiguratorContext.Provider>
  );
}

function loadConfig(location, configs) {
  return configs.filter((config) => location.pathname.startsWith(config.path))[0];
}

export default Routes;
