import React from 'react';
import OptionVehicule from './OptionVehicule';

const OptionSet1 = ({ setOption, form, summary }) => (
  <>
    <div className="grid gap-6 items-center justify-center">
      <OptionVehicule setOption={setOption} selected={form.vehicule !== '0'} price={summary.options.vehicule} />
    </div>
  </>
);

export default { set1: OptionSet1 };
