import React from 'react';
import PolicyItem from '@components/PolicyItem';
import Banner from '@components/Banner';

export default ({ form }) => (
  <>
    <PolicyItem
      icon={<i className="far fa-poll-people" />}
      title="Droit social"
      description={(
        <>
          L’employé que vous venez de licencier conteste la durée du
          délai de préavis
        </>
      )}
    />
    <PolicyItem
      icon={<i className="far fa-calculator" />}
      title="Droit fiscal"
      description={(
        <>
          Le fisc refuse de prendre en compte une partie des charges
          professionnelles que vous avez déclarées
        </>
  )}
    />
    <PolicyItem
      icon={<i className="far fa-file" />}
      title="Sanctions administratives communales & Amendes administratives"
      description={(
        <>
          La commune vous réclame une sanction administrative car vous n’avez pas jeté correctement
          des produits potentiellement dangereux utilisés dans le cadre de l’exploitation de votre entreprise
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-building" />}
      title="Contractuel location"
      description={(
        <>
          Le bailleur de votre atelier augmente unilatéralement le
          loyer.

          Vous êtes en conflit avec le bailleur de votre bâtiment d’entreprise, à propos du non respect du délai de préavis de votre bail commercial
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-handshake" />}
      title="Médiation All-in"
      description={(
        <>
          Vous pouvez compter sur une prise en charge d’un médiateur Agréé en cas de recours à une médiation civile, commerciale ou sociale
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-arrow-circle-down" />}
      title="Legal Insurance Services"
      description={(
        <>
          La garantie est acquise chaque fois que l’événement rencontré entre dans le cadre d’une garantie, un jurisite vous donnera un avis éclairé.
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-euro-sign" />}
      title="My Money Back"
      description={(
        <>
          Utilisez My Money Back pour recouvrer vos factures B2B impayées et non contestées.

          Vous bénéficiez avec le contrat  d’une réduction de 50 % sur l’avance à payer.
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-user-tie" />}
      title="My legal expert"
      description={(
        <>
          Prendre rendez-vous en  2 minutes avec un expert juridique.

          Nos experts vous accompagnent dans toutes vos démarches juridiques.
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-check" />}
      title="Droit des sociétés"
      description={(
        <>
          Prendre rendez-vous en  2 minutes avec un expert juridique.

          Nos experts vous accompagnent dans toutes vos démarches juridiques.
        </>
      )}
    />

    <PolicyItem
      icon={<i className="far fa-building" />}
      title="Litige Immobiliers"
      description={(
        <>
          Les réparations au toit de l’immeuble servant à l’exercice
          de votre activité professionnelle ont été mal réalisées
        </>
      )}
    />

  </>
);
