import React from 'react';
import Form from '@components/form/Form';

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ has_auditor: (selected) ? 'false' : 'true' })}
          k="has_auditor"
          label="Commissaire aux comptes"
          description="Le commissaire aux comptes est chargé de vérifier le caractère complet et correct des comptes préparés et présentés par le syndic."
          selected={selected}
        />
      </div>
    </>
  );
}
