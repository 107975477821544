import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

function Paging({ path, step, total }) {
  const bullets = Array.apply(null, Array(total)).map((e, i) => {
    const page = (i === 0) ? '' : i + 1;
    return (
      <li key={`li${i}`}>
        <Link
          to={`${path}/${page}`}
          className={classNames({
            'block w-3 h-3 rounded-full hover:bg-indigo-900': true, 'bg-primary': (step > i + 1), 'bg-secondary': (step === i + 1), 'bg-gray-200': (step < i + 1),
          })}
        >
          <span className="sr-only">
            Step
            {i}
          </span>
        </Link>
      </li>
    );
  });

  return (
    <div className="mt-12">
      <ol className="flex items-center space-x-2">
        {bullets}
      </ol>
    </div>
  );
}

export default Paging;
