import React, {
  useState,
} from 'react';
import Form from '@components/form/Form';
import Dialog from '@components/dialog/Dialog';

export default function Option({ setOption, selected }) {
  const [open, setOpen] = useState(false);

  const selectOption = (option) => {
    setOption(option);
    setOpen(false);
  };

  const dialogContent = (
    <>
      <div className="mt-2">
        <p className="text-sm text-gray-500">
          La couverture de base te permet de couvrir tes deux premiers chevaux sans supplément.
        </p>
      </div>

      <div className="mt-4 flex gap-2 items-center justify-center">
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={() => selectOption({ horse: 0 })}
        >
          Non
        </button>
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={() => selectOption({ horse: 1 })}
        >
          1
        </button>
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={() => selectOption({ horse: 2 })}
        >
          2
        </button>
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={() => selectOption({ horse: 3 })}
        >
          3
        </button>
        <button
          type="button"
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
          onClick={() => selectOption({ horse: 4 })}
        >
          4
        </button>
      </div>
    </>
  );
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.Option onChange={() => setOpen(true)} selected={selected} label="Chevaux" icon={<i className="fa fa-horse" />} k="horse" />
      </div>
      <Dialog open={open} setOpen={setOpen} title="Es-tu propriétaire de chevaux? Si oui de combien?" content={dialogContent} />
    </>
  );
}
