import React from 'react';
import Wrapper from '@components/Wrapper';
import { Link } from 'react-router-dom';
import Button from '@components/form/Button';

function ProductIndex() {
  return (
    <div
      className="w-full mt-32 m-auto max-w-4xl flex items-center justify-center flex-col mb-3"
    >
      <div className="text-center flex justify-center flex-col items-center px-6">
        <h1 className="text-gradient-primary text-6xl tracking-tight font-black">Besoin d'une assurance?</h1>
        <div className="mt-6">
          <p className="font-serif text-2xl text-gray-800">
            Configurez votre assurance et souscrivez en ligne.
          </p>
        </div>
        <div className="mt-28 grid grid-cols-1 md:grid-cols-2 md:gap-12 gap-y-12">
          <div className="p-8 rounded-lg bg-gray-50">
            <div className="-mt-12 mb-6">
              <span
                className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg text-white"
              >
                <i className="fa fa-heart" />
              </span>
            </div>
            <h3 className="text-xl mb-3 font-medium">Assurance Familiale</h3>
            <p className="text-gray-600">
              Protège votre famille des dommages corporels et/ou matériels causés à autrui
              dans le monde entier.
            </p>
            <Link to="assurance-familiale" className="block mt-6 text-primary">
              Configurer
              <i className="fa fa-arrow-right text-xs ml-2" />
            </Link>
          </div>

          <div className="p-8 rounded-lg bg-gray-50">
            <div className="-mt-12 mb-6">
              <span
                className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg text-white"
              >
                <i className="fa fa-rocket" />
              </span>
            </div>
            <h3 className="text-xl mb-3 font-medium">Aide Juridique Starter</h3>
            <p className="text-gray-600">
              Protégez toute votre famille dans de nombreux domaines juridiques.
            </p>
            <Link to="assurance-starter" className="block mt-6 text-primary">
              Configurer
              <i className="fa fa-arrow-right text-xs ml-2" />
            </Link>
          </div>

          <div className="p-8 rounded-lg bg-gray-50">
            <div className="-mt-12 mb-6">
              <span
                className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg text-white"
              >
                <i className="fa fa-balance-scale" />
              </span>
            </div>
            <h3 className="text-xl mb-3 font-medium">Aide Juridique Globale</h3>
            <p className="text-gray-600">Protégez toute votre famille dans de nombreux domaines juridiques.</p>
            <a href="assurance-aidejuridique" className="block mt-6 text-primary">
              Configurer
              <i className="fa fa-arrow-right text-xs ml-2" />
            </a>
          </div>

          <div className="p-8 rounded-lg bg-gray-50">
            <div className="-mt-12 mb-6">
              <span
                className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg text-white"
              >
                <i className="fa fa-car" />
              </span>
            </div>
            <h3 className="text-xl mb-3 font-medium">Assurance Oldtimer</h3>
            <p className="text-gray-600">Assurances des voitures ancêtres réservés exclusivement aux passionnés!</p>
            <a
              href="https://steylemansassurances.be/calculer-prime-assurance-oldtimer/"
              className="block mt-6 text-primary"
            >
              Configurer
              <i className="fa fa-arrow-right text-xs ml-2" />
            </a>
          </div>

          <div className="p-8 rounded-lg bg-gray-50">
            <div className="-mt-12 mb-6">
              <span
                className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg text-white"
              >
                <i className="fa fa-water" />
              </span>
            </div>
            <h3 className="text-xl mb-3 font-medium">Assurance Jetski</h3>
            <p className="text-gray-600">
              L’assurance couvre les dommages que vous occasionnez avec votre jet-ski à des
              personnes et/ou biens.
            </p>
            <Link to="assurance-jetski" className="block mt-6 text-primary">
              Configurer
              <i className="fa fa-arrow-right text-xs ml-2" />
            </Link>
          </div>

          <div className="p-8 rounded-lg bg-gray-50">
            <div className="-mt-12 mb-6">
              <span
                className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg text-white"
              >
                <i className="fa fa-building" />
              </span>
            </div>
            <h3 className="text-xl mb-3 font-medium">Assurance Syndic</h3>
            <p className="text-gray-600">
              Vous protège d'une négligence, oubli, retard, inexactitude, non-respect des
              délais, en règle générale n’importe quelle erreur quelconque peut vous être réclamée à titre
              personnel.
            </p>
            <Link to="assurance-syndic" className="block mt-6 text-primary">
              Configurer
              <i className="fa fa-arrow-right text-xs ml-2" />
            </Link>
          </div>

          <div className="p-8 rounded-lg bg-gray-50">
            <div className="-mt-12 mb-6">
              <span
                className="inline-flex items-center justify-center p-3 bg-indigo-500 rounded-md shadow-lg text-white"
              >
                <i className="fa fa-building" />
              </span>
            </div>
            <h3 className="text-xl mb-3 font-medium">Assurance Organes de la copropriété</h3>
            <p className="text-gray-600">
              Chaque organe de la copropriété porte sa propre responsabilité comme
              l’association des copropriétaires de l’immeuble; les membres du conseil de copropriété, le syndic
              bénévole, ou le commissaire aux comptes.
            </p>
            <Link to="assurance-syndic" className="block mt-6 text-primary">
              Configurer
              <i className="fa fa-arrow-right text-xs ml-2" />
            </Link>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ProductIndex;
