import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <></>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ vehicule: (selected) ? '0' : '1' })}
          k="vehicule"
          label="Couverture Véhicule(s)"
          description={(
            <>
              Auto Full est une assurance protection juridique de type TOUT SAUF qui couvre les sinistres qui pourraient survenir
              lors de vos déplacements. Nous nous engageons, aux conditions telles que prévues dans nos conditions générales, à vous aider en cas
              de sinistre couvert, à faire valoir vos droits à l’amiable et si nécessaire, par une procédure appropriée, en vous fournissant des services
              et en prenant en charge les frais qui en résultent.
            </>
            )}
          selected={selected}
          price={`${price}€/m`}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
