import React from 'react';

const Small = ({
  icon, title, description,
}) => (
  <>
    <div className="relative">
      <dt>
        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-gray-50">
          {icon}
        </div>
        <p className="ml-16 uppercase text-xs font-bold text-gray-800 mt-3 tracking-widest text-left">{title}</p>
      </dt>
      <dd className="mt-2 ml-16 text-sm text-gray-500 text-left">
        {description}
      </dd>
    </div>

  </>
);

const Large = ({
  option, icon, title, description,
}) => (
  <>
    <div className="flex items-center flex-col">
      <div className="bg-gray-50 rounded-2xl h-20 w-20 flex items-center justify-center text-3xl relative">
        { (option && (
        <div
          className="rounded-full bg-blue-500 h-6 w-6 text-white text-xs flex items-center justify-center -top-2 -right-2 absolute"
        >
          <i className="far fa-check" />
        </div>
        ))}
        {icon}
      </div>
      <p className="uppercase text-xs font-bold text-gray-800 mt-3 tracking-widest">{title}</p>
      <p className="text-gray-500 text-sm leading-6 mt-2">
        {description}
      </p>
    </div>
  </>
);

export default (props) => (
  <>
    { props.size === 'sm' && <Small {...props} /> }
    { props.size != 'sm' && <Large {...props} /> }
  </>
);
