import React from 'react';
import Avatar from '@components/avatar/Avatar';
import Button from '@components/form/Button';
import { Link } from 'react-router-dom';
import Banner from '@components/Banner';
import logoBrocom from '@images/brocom-logo-2.png';
import PriceBox from '@components/PriceBox';
import Options from './options/Options';
import IncludedPolicies from './policies/IncludedPolicies';
import ExcludePolicies from './policies/ExcludedPolicies';

function Order({
  form, summary, compute, pathToConfirm,
}) {
  const setOption = (option) => {
    compute(option);
  };
  return (
    <>
      <div className="md:sticky fixed w-full bg-white border-b border-gray-50 items-center flex-wrap py-5 m-auto top-0 animated z-10">
        <div className="flex justify-between px-8 ">
          <a className="hidden md:flex items-center group hover:scale-110 transform transition block" href="tel:3224790099">
            <i className="fad fa-phone-alt text-primary text-2xl mr-6" />
            <div className="text-left">
              <div className="text-sm">Tu peux me joindre au</div>
              <div className="text-sm text-primary">+32 2 479 00 99</div>
            </div>
          </a>
          <div className="flex items-center">
            <div className="mr-3">
              <span className="text-xl font-black">{summary.price}</span>
              <span className="text-sm">€/mois</span>
            </div>
            <Link to={pathToConfirm}><Button.Primary size="md" label="Ça me convient" /></Link>
          </div>
        </div>
      </div>
      <div className="w-full text-center flex justify-center flex-col items-center">
        <section className="w-full pb-16 md:pb-24 text-center flex justify-center flex-col items-center">
          <p className="md:max-w-3xl font-serif text-4xl md:text-6xl text-gray-800 mt-6 md:mt-24">
            Merci
            {' '}
            {form.first_name}
            ,
            <br />
            {' '}
            ta prime RC familiale est personnalisée.
          </p>
          <PriceBox price={summary.price} features={summary.features} pathToConfirm={pathToConfirm} billPeriod="mois" />
          <a
            href="#personnaliser"
            className="mt-6 block font-light text-gray-700"
          >
            Je préfère personnaliser mon assurance 👇
          </a>
        </section>

        <section className="w-full px-6 py-16 md:py-16 text-center flex justify-center flex-col items-center w-full ">
          <h2 className="text-3xl md:text-5xl font-black text-gray-800 mb-4">Ce qui est couvert</h2>
          <div className="mt-16 w-full">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-16 md:max-w-4xl m-auto">
              <IncludedPolicies form={form} />
            </div>
            <p className="mt-20">
              <Banner className="mt-3 cursor-pointer" link="/storage/IPID_RCF.pdf" label="Plus de détails" />
            </p>
          </div>
        </section>

        <section id="personnaliser" className="w-full px-6 py-16 md:py-24 text-center flex justify-center flex-col items-center w-full ">
          <h2 className="text-3xl md:text-5xl font-black text-gray-800">Options complémentaires</h2>
          <p className="text-sm md:text-base mt-4 text-gray-500">
            J'ai sélectionné les options sur base de notre entretien.
            <br />
            Elles permettent d’améliorer et de personnaliser ta couverture.
            <br />
            Libre à toi de les modifier :-)
          </p>
          <div className="mt-16 w-auto max-w-2xl">
            <Options.set2 setOption={setOption} form={form} summary={summary} />
          </div>
        </section>

        <section className="w-full px-6 bg-gray-50 py-16 md:py-24 text-center flex justify-center flex-col items-center w-full ">
          <h2 className="text-3xl md:text-5xl font-black text-gray-800">Personnaliser</h2>
          <p className="text-sm md:text-base mt-4 text-gray-500">Adapte ta couverture à tes besoins</p>
          <div className="mt-16">
            <Options.set1 setOption={setOption} form={form} summary={summary} />
          </div>
        </section>

        <section className="w-full px-6 py-16 md:py-16 text-center flex justify-center flex-col items-center w-full ">
          <h2 className="text-3xl md:text-5xl font-black text-gray-800 mb-4">Ce qui n'est pas couvert</h2>
          <div className="mt-16 w-auto ">
            <div className="grid md:grid-cols-2 gap-8 md:gap-16 md:max-w-4xl m-auto">
              <ExcludePolicies form={form} />
            </div>
          </div>
          <Banner className="mt-20" prefix="PDF" link="/storage/IPID_RCF.pdf" label="Télécharger la fiche produit" />
          <Banner className="mt-3" prefix="www" link="/storage/CG_RCF.pdf" label="Conditions générales" />
          <img className="mt-10 md:w-1/3" src={logoBrocom} />
        </section>

        <section className="md:hidden w-full py-16 mt-16 px-6 bg-gradient-primary w-full flex flex-col items-center justify-center">
          <Avatar />
          <p className="font-serif text-2xl text-white mt-6 font-bold">Nous pouvons parcourir ta couverture ensemble.</p>
          <div className="mt-12">
            <Button.Secondary label="Discuter" icon={<i className="fad fa-comments mr-4 text-primary text-xl" />} onClick={() => $crisp.push(['do', 'chat:open'])} />
            <a href="tel:3224790099">
              <Button.Secondary className="mt-3" label="Téléphoner" icon={<i className="fad fa-phone-alt mr-4 text-primary text-xl" />} />
            </a>
          </div>
        </section>
      </div>
    </>
  );
}

export default Order;
