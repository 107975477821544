import React from 'react';
import OptionSyndicVolunteer from './OptionSyndicVolunteer';
import OptionAuditor from './OptionAuditor';
import OptionCouncil from './OptionCouncil';

const OptionSet1 = ({ setOption, form }) => (
  <>
  </>
);

const OptionSet2 = ({ setOption, form, summary }) => (
  <>
    <div className="grid gap-6 items-center justify-center">
      <OptionSyndicVolunteer setOption={setOption} selected={form.is_syndic_volunteer === 'true'} />
      <OptionAuditor setOption={setOption} selected={form.has_auditor === 'true'} />
      <OptionCouncil setOption={setOption} selected={form.has_council === 'true'} />
    </div>
  </>
);

export default { set1: OptionSet1, set2: OptionSet2 };
