import Logo from '@images/logo.svg';
import React from 'react';
import Avatar from '@components/avatar/Avatar';

function Layout({ content }) {
  return (
    <div className="relative sm:pb-24">
      <div className="pt-6 mx-auto max-w-7xl px-6">
        <img src={Logo} alt="Your insurance expert" />
      </div>
      <div className="mt-16 mx-auto max-w-7xl md:mt-24">
        <div className="text-center flex justify-center flex-col items-center">
          <Avatar />
          {content}
        </div>
      </div>
    </div>
  );
}

export default Layout;
