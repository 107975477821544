import React from 'react';
import OptionHorse from './OptionHorse';
import OptionProtectionJuridique from './OptionProtectionJuridique';
import OptionPackFamilyPlus from './OptionPackFamilyPlus';
import OptionPackCarRental from './OptionPackCarRental';
import OptionGensMaison from './OptionGensMaison';

const OptionSet1 = ({ setOption, form }) => (
  <>
    <div className="grid gap-6 items-center justify-center">
      <OptionHorse setOption={setOption} selected={('horse' in form && form.horse > 0)} />
    </div>
  </>
);

const OptionSet2 = ({ setOption, form, summary }) => (
  <>
    <div className="grid gap-6 items-center justify-center">
      <OptionProtectionJuridique setOption={setOption} selected={form.with_protection_juridique} price={summary.options.protection_juridique} />
      <OptionPackFamilyPlus setOption={setOption} selected={form.do_lend} price={summary.options.pack_family_plus} />
      <OptionPackCarRental setOption={setOption} selected={form.do_rent_car} price={summary.options.pack_car_rental} />
      <OptionGensMaison setOption={setOption} selected={form.do_gens_maison} price={summary.options.gens_maison} />
    </div>
  </>
);

export default { set1: OptionSet1, set2: OptionSet2 };
