import React from 'react';
import StepWelcome from './StepWelcome';
import StepVehicule from './StepVehicule';
import StepTeam from './StepTeam';
import StepCreation from './StepCreation';
import Loader from './Loader';
import Order from './Order';
import Confirm from './Confirm';
import Thanks from '../../Thanks';

export default {
  steps: [<StepWelcome />, <StepVehicule />, <StepTeam />, <StepCreation />],
  Loader,
  Order,
  Confirm,
  Thanks,
};
