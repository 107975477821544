import React, { useEffect, useContext } from 'react';
import Paging from '@components/paging/Paging';
import Wrapper from '@components/Wrapper';
import StepContainer from '@components/step/StepContainer';
import {
  useParams,
  Link,
} from 'react-router-dom';
import ConfiguratorContext from '@containers/ConfiguratorContext';

function Form({
  form, summary, updateForm, productComponents,
}) {
  const { config } = useContext(ConfiguratorContext);
  const { id } = useParams();
  const step = (id) ? parseInt(id) : 1;

  const props = [];
  props.nextStepPath = (step < config.stepsCount - 1) ? `${config.path}/start/${step + 1}` : `${config.path}/loading`;
  props.updateForm = updateForm;
  props.form = form;
  const stepComponent = React.cloneElement(productComponents.steps[step - 1], props);
  const stepContainer = <StepContainer step={stepComponent} summary={summary} />;

  return (
    <Wrapper>
      {(step > 1) && (
        <div className="flex justify-center">
          <Link to={`${config.path}/start/${step - 1}`} className="hidden md:flex text-sm items-center justify-center text-primary rounded-full bg-gray-50 w-12 h-12 justify-center">
            <i className="fal fa-arrow-left" />
          </Link>
        </div>
      )}
      { stepContainer }
      <div className="flex items-center justify-center">
        { (step > -1) ? <Paging path={config.path} step={step} total={productComponents.steps.length} /> : null }
      </div>
    </Wrapper>
  );
}

export default Form;
