import React, { useState } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ form, updateForm, nextStepPath }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'is_belgian_resident',
    defaultValue: form.is_belgian_resident,
  });

  const submitStep = (data) => {
    updateForm(data, nextStepPath);
  };

  const selectedOptions = [
    { k: 'motor', label: 'Bateau à moteur', selected: (optionValue === 'motor') },
    { k: 'sail', label: 'Bateau à voile', selected: (optionValue === 'sail') },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">Quel type de bateau souhaites-tu assurer?</p>
      </div>

      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Options
          options={selectedOptions}
          onChange={(value) => setValue('is_belgian_resident', value)}
        />
      </div>

      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.is_belgian_resident}
          {...register('is_belgian_resident', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
