import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Amendes, transactions pénales, astreintes, frais de poursuite
          pénale.
        </>
)}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description="Fait intentionnel (> 16 ans)"
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Faute lourde (> 16 ans) : ivresse ou état analogue, paris ou
          défis, crimes ou délits volontaires
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Usage autre que la navigation de plaisance à titre récréatif
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Vétusté ou mauvais état de la coque ou des appareils
          moteurs compétition, y compris entrainements et essais
          (hors régate)
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Absence de certificats d’aptitude exigés par les autorités.
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Transport en surnombre
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Moyens de sauvetage insuffisants pour tous les passagers
        </>
      )}
    />
  </>
);
