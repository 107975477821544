import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description="Les sinistres relatifs aux poursuites pénales d’un assuré pour des crimes ou des crimes correctionnalisés"
    />

    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Qui résultent d’un simple défaut de paiement par l’assuré ou par un tiers et ce sans contestation
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Résultant d’un fait intentionnel de l’assuré
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Contractuels relatifs à l’achat, à la vente ou à la gestion de valeurs mobilières
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Concernant le droit intellectuel Portant sur des droits à l’image
        </>
          )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Relatifs aux actions collectives, des faillites, concordats et aux fermetures
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Nous ne prenons pas en charge le paiement des amendes judiciaires, fiscales, transactionnelles et administratives et de leurs accessoires
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Les litiges entre associés
        </>
      )}
    />
  </>
);
