import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <div className="grid grid-cols-1 mt-6 pt-6 border-t border-gray-200 text-gray-800 text-xs">
    <div>
      <span className="uppercase tracking-wider font-bold">Ce qui est compris</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Vous êtes couvert à hauteur de 125.000EUR par sinistre et par an.
          Toute erreur, omission ou négligence, de droit ou de fait,
          commise dans l’exercice de l’activité professionnelle
          visée.
        </div>
      </div>
    </div>
  </div>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ is_syndic_volunteer: (selected) ? 'false' : 'true' })}
          k="is_syndic_volunteer"
          label="Syndic volontaire"
          description="L'activité de syndic est réalisée par un copropriétaire à titre bénévole."
          selected={selected}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
