import React from 'react';
import Logo from '@images/logo.svg';
import Avatar from '@components/avatar/Avatar';
import Button from '@components/form/Button';
import { Link } from 'react-router-dom';

function Layout({ content }) {
  return (
    <div className="bg-gray-50 relative flex h-screen overflow-hidden">
      <div className="w-1/3 p-6">
        <Link to="/"><img src={Logo} alt="Your insurance expert" /></Link>
        <div className="min-h-full flex items-center justify-center flex-col ">
          <Avatar size="sm" />
          <p className="mt-6 text-2xl font-bold flex items-center">
            Ambre
            <span className="ml-2 h-4 w-4 rounded-full bg-secondary block" />
          </p>
          <p className="text-xl text-gray-400">Customer Happiness Manager @steylemans</p>
          <div className="flex flex-col gap-2 mt-10">
            <a href="mailto:welcome@steylemans.pro" target="_blank" rel="noreferrer">
              <Button.Secondary className="" label="Email" icon={<i className="fad fa-envelope mr-4 text-primary text-xl" />} />
            </a>
            <a href="tel:+3224790099" rel="noreferrer">
              <Button.Secondary className="" label="+32 2 479 00 99" icon={<i className="fad fa-phone mr-4 text-primary text-xl" />} />
            </a>
          </div>
        </div>
      </div>
      <div className="w-2/3 bg-white rounded-l-3xl shadow-2xl overflow-y-auto overflow-hidden relative">
        <main className="w-full focus:outline-none">
          {content}
        </main>
      </div>
    </div>
  );
}

export default Layout;
