import React from 'react';
import Form from '@components/form/Form';

const MoreInfo = () => (
  <div className="grid grid-cols-1 mt-6 pt-6 border-t border-gray-200 text-gray-800 text-xs">
    <div>
      <span className="uppercase tracking-wider font-bold">Ce qui est compris</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Prévention et information juridique et remboursement des frais judiciaires.
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Prévoit ainsi une intervention amiable ou judiciaire pour te défendre en procédure pénale devant les tribunaux (jusqu'à 50.000 EUR de frais exposés)
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Prévoit de faire valoir vos droits vis-à-vis du propriétaire de l’autre bateau (jusqu'à 50.000 EUR de frais exposés)
        </div>
        <div className="flex items-start">
          <i className="fad fa-check-circle text-green-300 mr-2 mt-1" />
          Insolvabilité du tiers responsable (jusqu'à maximum 6.200 EUR)
        </div>
      </div>
    </div>
    <div className="mt-6">
      <span className="uppercase tracking-wider font-bold">Exemples de dommages non couverts</span>
      <div className="mt-3 space-y-2">
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Poursuites pénales pour tout fait intentionnel de l’assuré.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Le paiement des amendes judiciaires, fiscales, transactionnelles administratives et de leurs accessoires est exclu de la garantie.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Lorsque le bateau est donné en location lorsque le bateau est utilisé à d’autres fins que la navigation de plaisance à titre récréatif.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Pour les contestations faisant l'objet du sinistre portent sur la copropriété du bateau désigné.
        </div>
        <div className="flex items-start">
          <i className="fad fa-times-circle text-red-300 mr-2 mt-1" />
          Si le sinistre survient alors que le bateau est conduit par une personne qui ne remplit pas les conditions légalement requises pour naviguer.
        </div>
      </div>
    </div>
  </div>
);

export default function Option({ setOption, selected, price }) {
  return (
    <>
      <div className=" inset-0 flex items-center justify-center">
        <Form.OptionLarge
          onChange={() => setOption({ with_protection_juridique: (selected) ? 'false' : 'true' })}
          k="protection_juridique"
          label="Assistance pour la récupération d’un dommage"
          description="Indemnisation des frais de défense pénale, de recours civil."
          selected={selected}
          price={`${price}€/m`}
          moreInfo={<MoreInfo />}
        />
      </div>
    </>
  );
}
