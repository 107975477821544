import React, { useContext, useEffect, useState } from 'react';
import Form from '@components/form/Form';
import Wrapper from '@components/Wrapper';
import Button from '@components/form/Button';
import { useHistory, Link } from 'react-router-dom';
import { useForm, useFormState } from 'react-hook-form';
import ConfiguratorContext from '@containers/ConfiguratorContext';
import ApiPostConfirm from '../../../api/post-confirm';
import stepValidator from '../../../utils/stepValidator';

function Confirm({
  form, pathToOrder, pathToThanks, summary,
}) {
  const {
    register, handleSubmit, formState: { errors }, control,
  } = useForm({
    mode: 'onChange',
  });
  const configContext = useContext(ConfiguratorContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isValid } = useFormState({
    control,
  });

  useEffect(() => {
    stepValidator(history, form, configContext.config.stepsCount, configContext.config);
  }, []);

  useEffect(() => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = 'd9b196c9-ca96-4c3d-8dd1-a805bbaccbed';

    (function () {
      const d = document;
      const s = d.createElement('script');
      s.src = 'https://client.crisp.chat/l.js';
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    }());
  }, []);

  const postForm = (data) => {
    const payload = { subscription_form: data, form, price: summary.price };
    setIsLoading(true);
    ApiPostConfirm(payload, form.product)
      .then(() => {
        history.push(pathToThanks);
      })
      .catch(() => {
      });
  };

  return (
    <Wrapper className="text-center flex justify-center flex-col items-center px-6">
      <div className="flex justify-center">
        <Link to={pathToOrder} className="hidden md:flex text-sm items-center justify-start text-primary rounded-full bg-gray-50 w-12 h-12 justify-center">
          <i className="fal fa-arrow-left" />
        </Link>
      </div>
      <p className="font-serif text-3xl text-gray-800 mt-6">
        Votre demande de contrat est prête à être soumise!
        Merci de compléter les éléments essentiels à sa réalisation.
      </p>
      <form className="mt-10 gap-3 flex flex-col" onSubmit={() => buttonRef.current.click()}>

        <div className="gap-3 flex flex-col">

          <div className="flex gap-3">
            <Form.Input
              type="text"
              name="first_name"
              register={register}
              rules={{ required: false }}
              defaultValue={form.first_name}
              autoFocus
              label="Prénom"
              placeholder="Prénom"
              error={errors.first_name}
            />
            <Form.Input
              type="text"
              name="last_name"
              register={register}
              rules={{ required: false }}
              defaultValue={form.last_name}
              autoFocus
              label="Nom"
              placeholder="Nom"
              error={errors.last_name}
            />
          </div>
          <div className="flex gap-3">
            <Form.Input
              type="date"
              name="birth_date"
              register={register}
              rules={{ required: false }}
              defaultValue={form.birth_date}
              autoFocus
              label="Date de naissance"
              placeholder="Date de naissance"
              error={errors.birth_date}
            />
            <Form.Input
              type="text"
              name="nationality"
              register={register}
              rules={{ required: false }}
              defaultValue={form.nationality}
              autoFocus
              label="Nationalité"
              placeholder="Nationalité"
              error={errors.nationality}
            />
          </div>
          <div className="flex gap-3">
            <div className="w-1/2">
              <Form.Input
                type="text"
                name="address_street"
                register={register}
                rules={{ required: false }}
                defaultValue={form.address_street}
                autoFocus
                label="Rue"
                placeholder="Rue"
                error={errors.address_street}
              />
            </div>
            <div className="flex w-1/2 gap-3">
              <Form.Input
                type="text"
                name="address_number"
                register={register}
                rules={{ required: false }}
                defaultValue={form.address_number}
                autoFocus
                label="Numéro"
                placeholder="Numéro"
                error={errors.address_number}
              />
              <Form.Input
                type="text"
                name="address_box"
                register={register}
                rules={{ required: false }}
                defaultValue={form.address_box}
                autoFocus
                label="Boite"
                placeholder="Boite"
                error={errors.address_box}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <div className="w-2/3">
              <Form.Input
                type="text"
                name="address_commune"
                register={register}
                rules={{ required: false }}
                defaultValue={form.address_commune}
                autoFocus
                label="Commune"
                placeholder="Commune"
                error={errors.address_commune}
              />
            </div>
            <div className="flex w-1/3 gap-3">
              <Form.Input
                type="text"
                name="address_postalcode"
                register={register}
                rules={{ required: false }}
                defaultValue={form.address_postalcode}
                autoFocus
                label="Code Postal"
                placeholder="Code Postal"
                error={errors.address_postalcode}
              />
            </div>
          </div>
          <div className="flex gap-3">
            <Form.Input
              type="email"
              name="email"
              register={register}
              rules={{ required: false }}
              defaultValue={form.email}
              autoFocus
              label="Email"
              placeholder="Email"
              error={errors.email}
            />
            <Form.Input
              type="text"
              name="phone"
              register={register}
              rules={{ required: false }}
              defaultValue={form.phone}
              autoFocus
              label="Téléphone"
              placeholder="Téléphone"
              error={errors.phone}
            />
          </div>

          <div className="text-left grid grid-cols-12 gap-3 items-center mt-12">
            <p className="col-span-8 font-serif text-lg text-gray-800">
              Langue
            </p>
            <div className="col-span-4 flex gap-x-8 items-center justify-start">
              <Form.Radio
                name="flag_language"
                register={register}
                defaultValue={form.flag_language}
                label="Français"
                error={errors.flag_language}
                value="FR"
              />
              <Form.Radio
                name="flag_language"
                register={register}
                defaultValue={form.flag_language}
                label="Nederlands"
                error={errors.flag_language}
                value="nl"
              />
            </div>

            <p className="col-span-8 font-serif text-lg text-gray-800">
              Je confirme que j' n'ai pas été résilié par une autre compagnie dans les 5 ans
            </p>
            <div className="col-span-4 flex gap-x-8 items-center justify-start">
              <Form.Radio
                name="flag_resiliation"
                register={register}
                defaultValue={form.flag_resiliation}
                label="Oui"
                error={errors.flag_resiliation}
                value="true"
              />
              <Form.Radio
                name="flag_resiliation"
                register={register}
                defaultValue={form.flag_resiliation}
                label="Non"
                error={errors.flag_resiliation}
                value="false"
              />
            </div>

            <p className="col-span-8 font-serif text-lg text-gray-800">
              Nombre de sinistre en 5 ans
            </p>
            <div className="col-span-4 flex gap-x-8 items-center justify-start">
              <Form.Radio
                name="flag_sinistre_count"
                register={register}
                defaultValue={form.flag_sinistre_count}
                label="0 - 1"
                error={errors.flag_sinistre_count}
                value="1"
              />
              <Form.Radio
                name="flag_sinistre_count"
                register={register}
                defaultValue={form.flag_sinistre_count}
                label="Plus de 1"
                error={errors.flag_sinistre_count}
                value="1_more"
              />
            </div>
          </div>
        </div>
        <div>
          <p className="mt-12 mb-3 font-serif text-xl text-gray-800">
            D'autres informations ou remarques?
          </p>

          <Form.Textarea
            register={register}
            rules={{ required: false }}
            name="remarks"
            error={errors.remarks}
          />
        </div>

        <div>
          <p className="mt-12 mb-3 font-serif text-xl text-gray-800">
            Je souhaite recevoir une offre pour mes autres contrats d'assurance
          </p>
          <div className="flex gap-x-8 items-center justify-center">
            <Form.Radio
              name="flag_want_other_insurance"
              register={register}
              defaultValue={form.flag_want_other_insurance}
              label="Oui"
              error={errors.flag_want_other_insurance}
              value="true"
            />
            <Form.Radio
              name="flag_want_other_insurance"
              register={register}
              defaultValue={form.flag_want_other_insurance}
              label="Non"
              error={errors.flag_want_other_insurance}
              value="false"
            />
          </div>

        </div>

        <Form.Checkbox
          register={register}
          rules={{ required: true }}
          label={(
            <>
              Dans le cadre de ma demande d'information pour une assurance, je suis d'accord de recevoir par email mon éventuel futur contrat (
              <a href="https://steylemansassurances.be/mentions-legales/" target="_blank" rel="noreferrer" className="underline">conditions générales</a>
              , la
              {' '}
              <a href="https://steylemansassurances.be/regles-mifid/" target="_blank" rel="noreferrer" className="underline">notice d'information générale</a>
              {' '}
              et notre
              {' '}
              <a href="https://steylemansassurances.be/application-terms/" target="_blank" rel="noreferrer" className="underline">disclaimer</a>
              ).
            </>
          )}
          name="terms"
          error={errors.terms}
        />
        <div className="mt-6">
          <Button.Primary label="Envoyer" type="button" delay disabled={!isValid} onClick={handleSubmit(postForm)} />
        </div>
      </form>
    </Wrapper>
  );
}

export default Confirm;
