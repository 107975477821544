import React, { useEffect, useState } from 'react';
import Routes from '@containers/Routes';
import SmLayout from '@containers/layout/SmLayout';
import MdLayout from '@containers/layout/MdLayout';
import { useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import config from './products/jetski/config.json';
import ApiPostForm from '../api/post-form';

const Layout = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1280px)' });
  return (isTabletOrMobile) ? <SmLayout content={children} /> : <MdLayout content={children} />;
};

function Configurator() {
  const [form, setForm] = useState(config.form);
  const [summary, setSummary] = useState({ price: 10 });
  const [triggerCompute, setTriggerCompute] = useState(null);
  const history = useHistory();

  const updateForm = (data, route = null) => {
    setForm({ ...form, ...data });
    if (route) {
      history.push(route);
    }
  };

  const compute = () => {
    ApiPostForm(form)
      .then((data) => {
        setSummary(data);
        setTriggerCompute(false);
      })
      .catch(() => {
      });
  };

  const updateFormAndCompute = (data) => {
    setForm({ ...form, ...data });
    setTriggerCompute(true);
  };

  useEffect(() => {
    if (triggerCompute) {
      compute();
    }
  }, [triggerCompute]);

  return (
    <div>
      <Layout>
        <Routes
          form={form}
          setForm={setForm}
          summary={summary}
          updateFormAndCompute={updateFormAndCompute}
          updateForm={updateForm}
          compute={compute}
        />
      </Layout>
    </div>
  );
}

export default Configurator;
