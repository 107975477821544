import React from 'react';
import StepWelcome from './StepWelcome';
import StepVehicule from './StepVehicule';
import StepTier from './StepTier';
import Loader from './Loader';
import Order from './Order';
import Confirm from './Confirm';
import Thanks from '../../Thanks';

export default {
  steps: [<StepWelcome />, <StepVehicule />, <StepTier />],
  Loader,
  Order,
  Confirm,
  Thanks,
};
