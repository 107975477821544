import React from 'react';
import OptionProtectionJuridique from './OptionProtectionJuridique';

const OptionSet1 = ({ setOption, form, summary }) => (
  <>
    <div className="grid gap-6 items-center justify-center">
      <OptionProtectionJuridique setOption={setOption} selected={form.with_protection_juridique === 'true'} price={summary.options.protection_juridique} />
    </div>
  </>
);

export default { set1: OptionSet1 };
