import React, { useState } from 'react';
import Form from '@components/form/Form';
import Button from '@components/form/Button';
import ChatBox from '@components/chat/ChatBox';
import { useForm, useFormState, useWatch } from 'react-hook-form';

function Step({ form, updateForm, nextStepPath }) {
  const {
    register, handleSubmit, formState: { errors }, control, setValue,
  } = useForm({
    mode: 'onChange',
  });
  const { isValid } = useFormState({
    control,
  });

  const optionValue = useWatch({
    control,
    name: 'shell_material',
    defaultValue: form.shell_material,
  });

  const submitStep = (data) => {
    updateForm({
      shell_material: (data.shell_material) ? data.shell_material.id : null,
    }, nextStepPath);
  };

  const selectedOptions = [
    { id: 'polyester', name: 'Polyester' },
    { id: 'acier', name: 'Acier' },
    { id: 'aluminium', name: 'Aluminium' },
    { id: 'bois', name: 'Bois' },
  ];

  return (
    <div className="text-center flex justify-center flex-col items-center px-6">
      <div className="mt-6">
        <p className="font-serif text-3xl text-gray-800">Quel est le matériau de la coque?</p>
      </div>

      <div className="flex mt-12 w-2/3 flex-col space-y-4">
        <Form.Select
          placeHolder="Materiau"
          options={selectedOptions}
          selected={optionValue}
          setSelected={(value) => setValue('shell_material', value)}
        />
      </div>

      <div className="mt-16">
        <Button.Primary delay label="Suivant" onClick={handleSubmit(submitStep)} disabled={!isValid} />
        <input
          type="hidden"
          defaultValue={form.shell_material}
          {...register('shell_material', { required: true })}
        />
        {errors.optionValue && <p className="mt-3 text-sm text-red-400">Veuillez sélectionner une réponse.</p>}
      </div>
    </div>
  );
}

export default Step;
