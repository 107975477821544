import React from 'react';
import PolicyItem from '@components/PolicyItem';

export default ({ form }) => (
  <>
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description="Si le sinistre est la conséquence d’une des fautes lourdes suivantes : vous vous trouvez en état d’ivresse, d’intoxication alcoolique punissable, ou dans un état analogue résultant de l’utilisation d’autres produits"
    />

    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Si le sinistre est la conséquence du fait que vous ne remplissez pas aux conditions prescrites par les lois et règlements pour pouvoir conduire le véhicule ou le véhicule n’est pas légalement autorisé à circuler
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          En cas de participation du véhicule assuré à une course, un concours de vitesse, de régularité ou d’adresse
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Lorsque vous causez intentionnellement le sinistre
        </>
      )}
    />
    <PolicyItem
      size="sm"
      icon={<i className="far fa-times" />}
      description={(
        <>
          Lorsque seuls des dommages matériels ont été encourus et lorsque le preneur d’assurance s’oppose par écrit à ce qu’un
          assuré fasse appel à la garantie pour faire valoir des droits à l’égard d’un autre assuré
        </>
          )}
    />
  </>
);
