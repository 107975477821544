import React, {
  useState,
} from 'react';
import Dialog from '@components/dialog/Dialog';
import Banner from '@components/Banner';

export default function Option() {
  const [open, setOpen] = useState(false);

  const dialogContent = (
    <>
      <Banner className="mt-3" prefix="PDF" link="/storage/STARTER_CG_PJ_PRO_4500555-04_2023.pdf" label="Conditions générales (avec voiture)" />
      <Banner className="mt-3" prefix="PDF" link="/storage/STARTER_Auto_-_Full_-_Fiches_IPID_-_20201012.pdf" label="Fiche produit auto (IPID)" />
      <Banner className="mt-3" prefix="PDF" link="/storage/STARTER_4500557-11.2023FICHEIPIDLegalVillageBusinessProstarter.pdf" label="Fiche produit protection juridique Starter (IPID)" />
    </>
  );
  return (
    <>
      <div className="inset-0 flex items-center justify-center flex-col">
        <p>Tous les détails & toutes les garanties 👇</p>
        <Banner className="mt-3 cursor-pointer" prefix="PDF" onClick={() => setOpen(true)} label="Conditions & fiches produits" />
      </div>
      <Dialog open={open} setOpen={setOpen} title="Conditions & fiches produits" content={dialogContent} />
    </>
  );
}
